<template>
  <div>
    <!-- Breadcrumb -->
    <button
      @click="open = true"
      class="px-6 py-2 mt-3 font-medium tracking-wide text-white bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none"
    >
      Créer
    </button>

    <div
    v-if="open"
      :class="`modal ${
        !open && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="open = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Ajouter un article</p>
            <div class="z-50 cursor-pointer modal-close" @click="open = false">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">N° Article</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">qr_code_2</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Dépot</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">warehouse</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Quantité</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">inventory</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Description</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">description</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              />
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="open = false"
              class="p-3 px-6 py-3 mr-2 text-indigo-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-indigo-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              @click="open = false"
              class="px-6 py-3 font-medium tracking-wide text-white bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal pour un article -->
    <div
    v-if="openArticle"
      :class="`modal ${
        !openArticle && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openArticle = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">
              Article : {{ selectedArticle.Ref_fournisseur }}
            </p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openArticle = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Dimension</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round"> straighten </span>
              </span>

              <div
                type="text"
                class="w-full px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              >
                Poids : {{ selectedArticle.Poids }} g <br />
                Hauteur : {{ selectedArticle.Hauteur }} cm <br />Longueur :
                {{ selectedArticle.Longueur }} cm <br />Largeur :
                {{ selectedArticle.Largeur }} cm
              </div>
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Prix</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round"> sell </span>
              </span>

              <div
                type="text"
                class="w-full px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              >
                Prix : {{ parseFloat(selectedArticle.Prix_euro).toFixed(2) }} €
              </div>
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Quantité</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">inventory</span>
              </span>

              <input
                type="text"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              />
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Description</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round">description</span>
              </span>

              <input
                type="text"
                :placeholder="selectedArticle.Ref_fournisseur"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
              />
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="openArticle = false"
              class="p-3 px-6 py-3 mr-2 text-indigo-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-indigo-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              @click="openArticle = false"
              class="px-6 py-3 font-medium tracking-wide text-white bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modal pour un article -->
    <div class="flex flex-col mt-3 sm:flex-row">
      <div class="flex">
        <div class="relative">
          <select
            class="block focus:outline-2 outline-sky-300 w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:bg-white"
          >
            <option>5</option>
            <option>10</option>
            <option>20</option>
          </select>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>

        <div class="relative">
          <select
            class="block focus:outline-2 outline-sky-300 w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border-t border-b border-r border-gray-400 rounded-r appearance-none sm:rounded-r-none sm:border-r-0 focus:bg-white"
          >
            <option>All</option>
            <option>Active</option>
            <option>Inactive</option>
          </select>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="relative block mt-2 sm:mt-0">
        <span class="absolute inset-y-0 left-0 flex items-center pl-2">
          <svg viewBox="0 0 24 24" class="w-4 h-4 text-gray-500 fill-current">
            <path
              d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
            />
          </svg>
        </span>

        <input
          placeholder="Chercher un article"
          v-model="articleSearch"
          v-on:keyup.enter="getOneArticle()"
          class="block w-full focus:outline-2 outline-sky-300 py-2 pl-8 pr-6 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none sm:rounded-l-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700"
        />
      </div>
    </div>
    <div class="flex flex-col mt-8">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Ref
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Fournisseur
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Famille d'article
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Sous famille d'article
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Stock
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(a, index) in Articles"
                :key="index"
                class="hover:bg-gray-50"
                style="cursor: pointer"
                @click="selectArticle(a)"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <!-- <div class="flex-shrink-0 w-10 h-10">
                      <img
                        class="w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt
                      />
                    </div>-->

                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ a.Ref_fournisseur.replace(/[^A-Z0-9]+/gi, "") }}
                      </div>
                    </div>
                  </div>
                  <div class="text-sm leading-5 text-gray-900">
                    {{ a.Code_EAN }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ a.Code_marque }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ a.Description }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ getFamille(a.Code_famille_NU) }}
                  </div>
                  <!-- <div class="text-sm leading-5 text-gray-500">famille1</div> -->
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ getSousFamille(a.Code_sousfamille_NU) }}
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    FAG
                    <div class="inline-flex mt-2 xs:mt-0">
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                    </div>
                  </div>
                  <div class="text-sm leading-5 text-gray-900">
                    EPE
                    <div class="inline-flex mt-2 xs:mt-0">
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                    </div>
                  </div>
                  <div class="text-sm leading-5 text-gray-900">
                    GAP
                    <div class="inline-flex mt-2 xs:mt-0">
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                      <button
                        class="px-3 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                      >
                        0
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="flex flex-col items-center px-5 py-5 bg-white border-t xs:flex-row xs:justify-between"
          >
            <span class="text-xs text-gray-900 xs:text-sm"
              >{{ startPaginate }} à {{ paginateNextStep - 1 }} sur
              {{ articleNumber }} Articles</span
            >

            <div class="inline-flex mt-2 xs:mt-0">
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-l hover:bg-gray-400"
              >
                Précédent
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                @click="downArrayPaginate()"
              >
                <span class="material-icons-round"> skip_previous </span>
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                v-for="(page, index) in paginateArray"
                :key="index"
                @click="getArticles(page.pageNumber * 10, 10)"
              >
                {{ page.pageNumber }}
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
                @click="upArrayPaginate()"
              >
                <span class="material-icons-round"> skip_next </span>
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-r hover:bg-gray-400"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import axios from "axios";
export default{
  name:"MyArticle",
  data() {
    return {
      open:false,
      openArticle:false,
      Articles:[],
      Familles:[],
      articleNumber: 0,
      familyName:"",
      sousfamilyName:"",
      paginateArray : [],
      paginateNextStep:11,
      startPaginate:1,
      selectedArticle: [],
      articleSearch:''
    }
  },
  methods: {
    getArticles(skipValue,limitValue) {
      axios.post(`${process.env.VUE_APP_API}/article/getall`,{skip:skipValue,limit:limitValue}).then((response)=>{
        this.Articles = response.data;
      });

      axios.get(`${process.env.VUE_APP_API}/article/count`).then((response)=>{
        this.articleNumber = response.data;
      });
    },
    getFamille(codeRecherche){
     axios.post(`${process.env.VUE_APP_API}/famillearticle/getone`,{Code:codeRecherche}).then((response)=>{
        this.familyName = response.data[0].Name;
     });
      return this.familyName;
    },
    getSousFamille(codeRecherchesous){
      axios.post(`${process.env.VUE_APP_API}/sousfamillearticle/getone`,{Code:codeRecherchesous}).then((response)=>{
        this.sousfamilyName = response.data[0].Name;
     });
      return this.sousfamilyName;
    },
    upArrayPaginate(){
      this.startPaginate = this.paginateNextStep;
      this.paginateArray.forEach((element,index) => {
        this.paginateArray[index] = {pageNumber:this.paginateNextStep};
        this.paginateNextStep=this.paginateNextStep+1;
      });
    },
    downArrayPaginate(){
      if(this.paginateNextStep !=11){
        this.paginateNextStep = this.paginateNextStep - 20;
        this.startPaginate = this.paginateNextStep;
        this.paginateArray.forEach((element,index) => {
          this.paginateArray[index] = {pageNumber:this.paginateNextStep};
          this.paginateNextStep=this.paginateNextStep+1;
        });
      }
    },
    initPaginateArray(){
      this.paginateArray = new Array(10);
      this.paginateArray = Array.from(this.paginateArray);
      var i = 1;
      this.paginateArray.forEach((element,index) => {
        this.paginateArray[index] = {pageNumber:i};
        i=i+1;
      });
    },
    selectArticle(articleTab){
      this.openArticle = true;
      this.selectedArticle = articleTab;
    },
    getOneArticle(){
      if(this.articleSearch != ''){
        axios.post(`${process.env.VUE_APP_API}/article/getone`,{Ref_fournisseur:this.articleSearch}).then((response)=>{
          this.Articles = response.data;
        });
      }else{
        this.getArticles(10,10);
      }
    }

  },
  mounted() {
    this.getArticles(10,10);
    this.initPaginateArray();
  },
}
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
