<template>
  <div>
    <backButton />
    <h1 class="flex justify-center">{{ tabClient.Name }}</h1>
    <div class="flex flex-row justify-between">
      <div class="flex flex-row space-x-2 items-center">
        <searchInput
          placeholder="Rechercher dans conditions"
          @searchWord="search(1, 10, $event)"
          @catchFalse="searchCatchError = $event"
          :searchStarted="searchInProgress"
          :catchError="searchCatchError"
        />
        <input
          list="brow2"
          v-model="fournisseur"
          @change="getCondition(1, 10)"
          class="w-28 block w-full focus:outline-2 outline-sky-300 px-2 py-2 text-sm text-gray-700 placeholder-gray-400 bg-white rounded-l rounded-r appearance-none focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none hover:bg-gray-300"
        />
        <datalist id="brow2">
          <option
            v-for="(c, index) in fournisseurList"
            :key="index"
            :value="c.CompanyName"
          ></option>
        </datalist>
      </div>
    </div>

    <div
      class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Marque
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Designation
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Famille
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Remise
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
            <tr v-for="(cond, indexC) in conditionList" :key="indexC">
              <td
                class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
              >
                {{ cond.fournisseur }}
              </td>

              <td
                class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap text-xs"
              >
                {{ cond.designation }}
              </td>

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                {{ cond.familleFournisseur }}
              </td>

              <td
                class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
              >
                {{ cond.conditions[clientPosition] }} %
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pageNumber
      :pageStart="pageStart"
      :pageNext="pageNext"
      :tabList="amountLines"
      :pageTab="pageTab"
      :pageTotal="Math.ceil(amountLines / 10)"
      :clickFunction="getCondition"
      :currentPage="currentPage"
      :typeList="'conditions'"
      @update:start="pageStart = $event"
      @update:tab="pageTab = $event"
      @update:next="pageNext = $event"
    />
  </div>
</template>
<script>
import backButton from "@/components/Button/back.vue";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      conditionList: [],
      fournisseurList: [],
      activePage: 0,
      pageAmount: 0,
      amountLines: 0,
      paginateArray: [],
      paginateNextStep: 11,
      startPaginate: 1,
      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      searchInProgress: false,
      fournisseur: "",
      clientPosition: null,
    };
  },
  components: {
    backButton,
    pageNumber,
    searchInput,
  },
  computed: {
    ...mapGetters(["user", "tabClient"]),
  },
  methods: {
    ...mapActions(["getTabClient"]),
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.plateform,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
    },
    async getCondition(skipValue, limitValue) {
      this.currentPage = skipValue;
      await axios
        .post(`${process.env.VUE_APP_API}/conditionFournisseur/getOneClient`, {
          plateform: this.user.plateform,
          fournisseur: this.fournisseur,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          this.conditionList = response.data.data;
          this.pageAmount = response.data.page;
          this.amountLines = response.data.count;
          this.checkRemiseSpec();
        });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getCondition(skipValue, limitValue);
        this.getFournisseurLength();
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(
            `${process.env.VUE_APP_API}/conditionFournisseur/searchConditionClient`,
            {
              plateform: this.user.plateform,
              fournisseur: this.fournisseur,
              searchTerm: searchTerm,
              skip: (skipValue - 1) * 10,
              limit: limitValue,
            }
          )
          .then((response) => {
            this.searchInProgress = false;
            this.conditionList = response.data.results;
            this.searchLength = response.data.count;
            this.checkRemiseSpec();
          })
          .catch((error) => {
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    selectPage(nb) {
      this.activePage = nb;
      axios
        .post(`${process.env.VUE_APP_API}/conditionFournisseur/getOneClient`, {
          plateform: this.user.plateform,
          fournisseur: this.fournisseur,
          skip: this.activePage,
        })
        .then((response) => {
          this.conditionList = response.data.data;
          this.checkRemiseSpec();
        });
    },
    previousPage() {
      this.activePage = this.activePage - 1;
      axios
        .post(`${process.env.VUE_APP_API}/conditionFournisseur/getOneClient`, {
          plateform: this.user.plateform,
          fournisseur: this.fournisseur,
          skip: this.activePage,
        })
        .then((response) => {
          this.conditionList = response.data.data;
          this.checkRemiseSpec();
        });
    },
    pageUp() {
      if (this.pageNext <= this.pageAmount) {
        this.pageStart = this.pageNext;
        this.paginateArray.forEach((element, index) => {
          this.paginateArray[index] = { pageNumber: this.pageNext };
          this.pageNext = this.pageNext + 1;
        });
      }
    },
    pageDown() {
      if (this.pageNext != 11) {
        this.pageNext = this.pageNext - 20;
        this.pageStart = this.pageNext;
        this.paginateArray.forEach((element, index) => {
          this.paginateArray[index] = { pageNumber: this.pageNext };
          this.pageNext = this.pageNext + 1;
        });
      }
    },
    initPaginateArray() {
      this.paginateArray = new Array(10);
      this.paginateArray = Array.from(this.paginateArray);
      var i = 1;
      this.paginateArray.forEach((element, index) => {
        this.paginateArray[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    async getPositionByClient() {
      const reqPosition = await axios.post(
        `${process.env.VUE_APP_API}/configurationCondition/getPositionByClient`,
        {
          plateform: this.user.plateform,
          client: this.tabClient.Name,
        }
      );

      this.clientPosition = reqPosition.data;
    },
    async checkRemiseSpec() {
      for (const cond of this.conditionList) {
        const req = await axios.post(
          `${process.env.VUE_APP_API}/conditionFournisseur/checkRemiseSpec`,
          {
            plateform: this.user.plateform,
            fournisseur: cond.fournisseur,
            famille: cond.familleFournisseur,
            client: this.tabClient.Name,
          }
        );

        if (req.data) {
          cond.conditions[this.clientPosition] = req.data;
        }
      }
    },
  },
  mounted() {
    this.getPositionByClient();
    this.getCondition(1, 10);
    this.getFournisseur();
    this.pageSwitch();
    this.initPaginateArray();
  },
  beforeMount() {
    this.getTabClient();
    if (this.tabClient.TableauRemiseState == false) {
      this.$router.push("/Dashboard");
    }
  },
};
</script>
<style></style>
