<template>
  <!-- Breadcrumb -->
  <div>
    <h5 class="text-xl pl-4 bg-blue-400 decoration-black underline">
      Insérer un fichier
    </h5>

    <div class="mt-4 space-x-6">
      <div
        class="bg-white grid grid-rows-3 px-4 py-4 space-y-2 rounded border border-black"
      >
        <input
          type="file"
          multiple
          @change="onFileChange"
          accept="image/png, image/gif, image/jpeg, image/jpg, image/ico, application/pdf"
          class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border border-gray-400 rounded-md shadow appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
        />

        <input
          type="text"
          class="w-full focus:outline-2 outline-sky-300 px-2 py-2 border border-gray-400 rounded-md shadow appearance-none focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500"
          placeholder="URL"
        />
      </div>

      <div class="flex justify-center items-center">
        <img v-if="url" :src="url" />
      </div>

      <button @click="submitFile()">Envoyer</button>
    </div>
  </div>
</template>

<script>
// import { Carousel, Slide } from 'vue-carousel';
import axios from "axios";
export default {
  name: "MyPromotions",
  data() {
    return {
      url: null,
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
  },
  submitFile() {
    /*
                Initialize the form data
            */
    let formData = new FormData();

    /*
                Add the form data we need to submit
            */
    formData.append("file", this.file);

    /*
          Make the request to the POST /single-file URL
        */
    axios.post("./dashboard", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
</script>

<style></style>
