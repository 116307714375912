<template>
  <!-- Breadcrumb -->
  <div class="" v-if="choose">
    <h5 class="text-gray-700" style="font-size: 30px">Gérer votre profil</h5>
    <hr
      style="
        height: 1px;
        color: #a9a9a9;
        background-color: #a9a9a9;
        border: none;
      "
    />
    <div class="space-y-16 mt-12">
      <div class="flex justify-center grid grid-cols-2 m-auto">
        <div
          class="flex bg-gray-50 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
          @click="
            (choose = false),
              (showSecurite = false),
              (showCommande = false),
              (showPaiement = false),
              (showRenseignement = true)
          "
        >
          <span
            class="material-icons-outlined rounded-full text-blue-600 m-4 p-2 w-16 h-16"
            style="font-size: 50px"
          >
            settings
          </span>
          <span class="flex flex-col py-4 px-8">
            <span class="text-xl"> Renseignements personnels </span>
            <span class="text-gray-600 pt-2">
              Consulter / Modifier les informations personnels
            </span>
          </span>
        </div>
        <div
          class="flex bg-gray-50 space-x-4 border rounded-md ml-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
          @click="
            (choose = false),
              (showCommande = false),
              (showPaiement = false),
              (showRenseignement = false),
              (showSecurite = true)
          "
        >
          <span
            class="material-icons-outlined rounded-full text-blue-600 m-4 p-2 w-16 h-16"
            style="font-size: 50px"
          >
            verified_user
          </span>
          <span class="flex flex-col py-4 px-8">
            <span class="text-xl"> Sécurité </span>
            <span class="text-gray-600 pt-2"> Modifier le mot de passe </span>
          </span>
        </div>
      </div>

      <div class="flex justify-center grid grid-cols-2">
        <div
          class="flex bg-gray-50 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
          @click="
            (choose = false),
              (showSecurite = false),
              (showPaiement = false),
              (showRenseignement = false),
              (showCommande = true),
              getCommandes()
          "
        >
          <span
            class="material-icons-outlined rounded-full text-blue-600 m-4 p-2 w-16 h-16"
            style="font-size: 50px"
          >
            inventory_2
          </span>
          <span class="flex flex-col py-4 px-8">
            <span class="text-xl"> Vos commandes </span>
            <span class="text-gray-600 pt-2"> Consulter vos commandes </span>
          </span>
        </div>
        <div
          class="flex bg-gray-50 space-x-4 border rounded-md ml-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
          @click="
            (choose = false),
              (showSecurite = false),
              (showCommande = false),
              (showRenseignement = false),
              (showPaiement = true)
          "
        >
          <span
            class="material-icons-outlined rounded-full text-blue-600 m-4 p-2 w-16 h-16"
            style="font-size: 50px"
          >
            euro_symbol
          </span>
          <span class="flex flex-col py-4 px-8">
            <span class="text-xl"> Vos paiements </span>
            <span class="text-gray-600 pt-2"> Gérer vos paiements </span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <!-- Renseignements personnels -->
    <div v-show="showRenseignement">
      <div
        class="flex flex-row items-center cursor-pointer w-24 rounded-full hover:shadow-lg px-2 text-gray-600 mb-2"
        @click="choose = !choose"
      >
        <span class="material-icons-round"> arrow_back </span>
        <span class="p-2 text-black">Retour</span>
      </div>

      <p class="bg-white w-fit px-2 mt-3 rounded-t">Vos informations</p>
      <div class="text-gray-700 rounded-tr rounded-b p-3 bg-white border-b">
        <div class="flex flex-col"></div>
      </div>
    </div>
    <!-- Fin Renseignements personnels -->

    <!-- Securite -->
    <div v-show="showSecurite">
      <div
        class="flex flex-row items-center cursor-pointer w-24 rounded-full hover:shadow-lg px-2 text-gray-600 mb-2"
        @click="choose = !choose"
      >
        <span class="material-icons-round"> arrow_back </span>
        <span class="p-2 text-black">Retour</span>
      </div>

      <p class="bg-white w-fit px-2 rounded-t">Mot de passe</p>
      <div
        class="text-gray-700 rounded-tr rounded-b py-2 px-1 bg-white border-b"
      >
        <p class="text-red-600" v-show="erreurmdp">
          Il y a eu problème sur votre demande. Veuillez contacter le support !
        </p>

        <p v-show="successmdp" class="text-green-600">
          Un email de réinitialisation de mot de passe vient de vous être envoyé
          !
        </p>

        <button
          @click="updatePassword()"
          class="justify-end px-6 py-2 mt-2 font-medium text-white bg-blue-500 rounded-md hover:bg-opacity-25"
        >
          Réinitialiser votre mot de passe
        </button>

        <div>------ ou ------</div>

        <div class="flex flex-col">
          <span v-show="errorNewPasswordWrong" class="text-red-500 italic"
            >Mot de passe incorrect</span
          >
          <span v-show="errorNewPassword" class="text-red-500 italic"
            >les mots de passes sont différents</span
          >
          <input
            type="password"
            class="w-80 p-1 border border-gray-300 rounded"
            placeholder="Mot de passe actuel"
            v-model="currentPassword"
          />
          <input
            v-model="newPassword"
            class="w-80 p-1 border border-gray-300 rounded"
            placeholder="Entrez votre nouveau mot de passe"
            type="password"
          />
          <input
            v-model="verifNewPassword"
            class="w-80 p-1 border border-gray-300 rounded"
            placeholder="Entrez une nouvelle fois votre nouveau mot de passe"
            type="password"
          />
          <button
            class="w-80 px-6 py-2 mt-2 font-medium text-white bg-blue-500 rounded-md hover:bg-opacity-25"
            @click="setNewPassword()"
          >
            Changer le mot de passe
          </button>
        </div>
      </div>
    </div>
    <!-- Fin Securite -->

    <!-- Commande -->
    <div v-show="showCommande">
      <div
        class="flex flex-row items-center cursor-pointer w-24 rounded-full hover:shadow-lg px-2 text-gray-600 mb-2"
        @click="choose = !choose"
      >
        <span class="material-icons-round"> arrow_back </span>
        <span class="p-2 text-black">Retour</span>
      </div>
    </div>
    <!-- Fin Commande -->

    <!-- Paiement -->
    <div v-show="showPaiement">
      <div
        class="flex flex-row items-center cursor-pointer w-24 rounded-full hover:shadow-lg px-2 text-gray-600 mb-2"
        @click="choose = !choose"
      >
        <span class="material-icons-round"> arrow_back </span>
        <span class="p-2 text-black">Retour</span>
      </div>
    </div>
    <!-- Fin Paiement -->
  </div>
</template>

<script>
import {
  getAuth,
  sendPasswordResetEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { mapGetters } from "vuex";

export default {
  name: "myProfil",
  data: () => ({
    erreurmdp: false,
    successmdp: false,
    choose: true,
    showRenseignement: false,
    showSecurite: false,
    showCommande: false,
    showPaiement: false,
    currentPassword: "",
    newPassword: "",
    verifNewPassword: "",
    errorNewPassword: false,
    errorNewPasswordWrong: false,
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    updatePassword() {
      const auth = getAuth();
      sendPasswordResetEmail(auth, this.user.email)
        .then(() => {
          this.successmdp = true;
        })
        .catch(() => {
          this.erreurmdp = true;
        });
    },
    setNewPassword() {
      if (this.newPassword === this.verifNewPassword) {
        this.errorNewPassword = false;
        const auth = getAuth();
        const credential = EmailAuthProvider.credential(
          this.user.email,
          this.currentPassword
        );
        reauthenticateWithCredential(auth.currentUser, credential)
          .then(() => {
            this.currentPassword = "";
            updatePassword(auth.currentUser, this.newPassword)
              .then(() => {
                this.newPassword = "";
                this.verifNewPassword = "";
                this.$router.push("/dashboard");
              })
              .catch(() => {
                this.errorNewPassword = true;
              });
          })
          .catch((e) => {
            this.errorNewPasswordWrong = true;
            console.log(e);
          });
      } else {
        this.errorNewPassword = true;
      }
    },
    getCommandes() {
      this.$router.push("/listecommandes");
    },
  },
};
</script>
