import axios from "axios";

export async function searchArticleClient(
  Ref_fournisseur: string,
  plateform: string,
  Client: string,
  groupeClient: string
) {
  const search = await axios.post(
    `${process.env.VUE_APP_API}/searchEngine/searchArticleClient`,
    {
      Ref_fournisseur: Ref_fournisseur,
      plateform: plateform,
      Client: Client,
      groupeClient: groupeClient,
    }
  );

  return search.data;
}

export async function prepareArticleClient(
  articleData: any,
  plateform: string,
  client: string,
  groupeClient: string,
  quantity: number
) {
  let dataTab: any = {};
  const prepare = await axios.post(
    `${process.env.VUE_APP_API}/searchEngine/prepareArticleDirect`,
    {
      articleData: articleData,
      plateform: plateform,
      client: client,
      groupeClient: groupeClient,
      quantity: quantity,
    }
  );

  dataTab = prepare.data.data;

  if (dataTab.Prefixe_tarif == "Vhi") {
    axios
      .get(
        `${process.env.VUE_APP_API}/vanheck/getPrixLot/${dataTab.Ref_fournisseur}`
      )
      .then((response) => {
        if (response.data.length > 0) {
          dataTab.PrixLotVH = true;
        } else {
          dataTab.PrixLotVH = false;
        }
      })
      .catch((error) => {
        dataTab.PrixLotVH = false;
      });
  }

  if (dataTab.Qte_mini && parseInt(dataTab.Qte_mini) > quantity) {
    dataTab.quantity = dataTab.Qte_mini;
  } else {
    dataTab.quantity = quantity;
  }
  dataTab.commentaire = "";
  dataTab.prixtotal = dataTab.Prix_euro;
  dataTab.prixfinal = dataTab.Prix_euro;
  articleData.stockData.length > 0
    ? (dataTab.stockData = articleData.stockData[0].stock)
    : (dataTab.stockData = 0);

  return dataTab;
}

export async function getFournisseurList(
  plateform: string,
  Article: object,
  Client: string,
  groupeClient: string,
  quantity: number
) {
  const fournisseurList = await axios.post(
    `${process.env.VUE_APP_API}/article/getFournisseurList`,
    {
      plateform: plateform,
      Article: Article,
      Client: Client,
      groupeClient: groupeClient,
      quantity: quantity,
    }
  );

  return fournisseurList.data;
}
