<template>
  <div>
    <!-- Breadcrumb -->
    <div
      v-if="openSave"
      :class="`modal ${
        !openSave && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openSave = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-black cursor-pointer modal-close"
        >
          <svg
            class="text-black fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Ajouter un article</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openSave = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="openSave = false"
              class="p-3 px-6 py-3 mr-2 text-indigo-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-indigo-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              @click="openSave = false"
              class="px-6 py-3 font-medium tracking-wide text-black bg-cyan-400 rounded-md hover:bg-cyan-200 focus:outline-none"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-3 sm:flex-row-reverse">
      <div class="flex">
        <div class="relative">
          <select
            v-model="documentType"
            class="block focus:outline-2 outline-sky-300 w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:bg-white"
          >
            <option value="Devis">Devis</option>
            <option value="Commande">Commande</option>
            <option value="Bon de livraison">Bon de livraison</option>
            <option value="Consigne">Consigne</option>
            <option value="Ordre de réparation">Ordre de réparation</option>
            <option value="Avoir">Avoir</option>
            <option value="Facturation">Facturation</option>
          </select>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <button
          class="px-6 py-2 ml-3 font-medium tracking-wide text-black bg-sky-400 rounded-md hover:bg-sky-300 focus:outline-none"
          @click="openSave = true"
        >
          Enregistrer
        </button>
      </div>
    </div>
    <!-- second select -->
    <div class="flex flex-col mt-3 sm:flex-row">
      <div class="flex">
        <div class="relative">
          <input
            v-on:keyup.enter="getHistorique(), (historiqueShow = true)"
            list="brow2"
            v-model="clientChosed"
            placeholder="Clients"
            class="block focus:outline-2 outline-sky-300 w-full py-2 pl-8 pr-6 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
          />
          <datalist id="brow2">
            <option
              v-for="(c, index) in Clients"
              :key="index"
              :value="c.Name"
            ></option>
          </datalist>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <!-- modal historique  -->
    <div
      v-if="historiqueShow"
      :class="`modal ${
        !historiqueShow && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="historiqueShow = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 78rem"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-black cursor-pointer modal-close"
        >
          <svg
            class="text-black fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Historique</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="historiqueShow = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div class="flex flex-row mt-3">
            <label>
              <input
                type="checkbox"
                class="w-5 h-5 text-cyan-400 rounded-md focus:ring-indigo-500 focus:outline-2 outline-sky-300"
                name="radio"
              /><span class="ml-2 text-gray-700">Devis</span>
            </label>
          </div>
          <div
            class="py-2 -my-2 mt-3 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
            style="height: 30vh"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr
                    v-for="(historiqueFile, index) in historique"
                    :key="index"
                    @click="
                      setHistoriqueFile(historiqueFile.Articles),
                        (historiqueShow = false)
                    "
                    style="cursor: pointer"
                    class="hover:bg-blue-50"
                  >
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      {{ historiqueFile.Type }} N° {{ historiqueFile.Numero }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Date : {{ historiqueFile.Date }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Client : {{ historiqueFile.Client }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Crée par : {{ historiqueFile.Name }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Prix Total : {{ historiqueFile.TotalPrice }} €
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Desc : {{ historiqueFile.Note }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Validation :
                      <span
                        class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                        v-if="historiqueFile.IsProcessed === true"
                      >
                        <span class="material-icons-outlined">
                          done
                        </span></span
                      >
                      <span
                        class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full"
                        v-else
                      >
                        <span class="material-icons-outlined">close</span></span
                      >
                    </th>

                    <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="historiqueShow = false"
              class="p-3 px-6 py-3 mr-2 text-indigo-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-indigo-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- fin modal historique -->

    <!--Body page-->

    <!--Modal Nouveau Rdv-->
    <div
      v-if="openRdv"
      :class="`modal ${
        !openRdv && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openRdv = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 42rem; max-height: 42rem"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-black cursor-pointer modal-close"
        >
          <svg
            class="text-black fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Prise de RDV</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openRdv = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div>
            <div class="flex space-x-4">
              <div class="mt-4 text-black">
                <label class="text-xs mr-4 py-2">Monteur</label>

                <div class="flex relative rounded-md shadow-sm">
                  <input
                    v-model="monteur"
                    size="51"
                    class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                </div>
              </div>

              <div class="mt-4 text-black">
                <label class="text-xs mr-4 py-2">Date début</label>

                <div class="flex relative rounded-md shadow-sm">
                  <input
                    v-model="datedebut"
                    type="datetime-local"
                    size="51"
                    class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                </div>
              </div>

              <div class="mt-4 text-black">
                <label class="text-xs mr-4 py-2">Date fin</label>

                <div class="flex relative rounded-md shadow-sm">
                  <input
                    v-model="datefin"
                    type="datetime-local"
                    size="51"
                    class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                </div>
              </div>
            </div>

            <div class="flex space-x-4">
              <div class="mt-4 text-black">
                <label class="text-xs mr-4 py-2">Code client</label>

                <div class="">
                  <select
                    v-model="refclient"
                    class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:bg-white"
                  >
                    <option value="DV" id="DV">DV</option>
                    <option value="PRO" id="PRO">PRO</option>
                  </select>
                </div>
              </div>

              <div class="mt-4 text-black">
                <label class="text-xs mr-4 py-2">Nom client</label>

                <div class="flex relative rounded-md shadow-sm">
                  <input
                    v-model="nomClient"
                    size="58"
                    class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                </div>
              </div>
            </div>

            <div class="mt-4 text-black">
              <label class="text-xs mr-4 py-2">N° CDE</label>

              <div class="flex relative rounded-md shadow-sm">
                <input
                  v-model="numCde"
                  size="51"
                  class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                />
              </div>
            </div>

            <div class="mt-4 text-black">
              <label class="text-xs mr-4 py-2">N° téléphone</label>

              <div class="flex relative rounded-md shadow-sm">
                <input
                  type="datetime"
                  v-model="numTel"
                  size="51"
                  class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                />
              </div>
            </div>

            <div class="mt-4 text-black">
              <label class="text-xs mr-4 py-2">Immatriculation</label>

              <div class="flex relative rounded-md shadow-sm">
                <input
                  type="datetime"
                  v-model="immatriculation"
                  size="51"
                  class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                />
              </div>
            </div>

            <div class="mt-4 text-black">
              <label class="text-xs mr-4 py-2">Travaux</label>

              <div class="flex relative rounded-md shadow-sm">
                <textarea
                  v-model="travaux"
                  size="51"
                  class="block w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                >
                </textarea>
              </div>
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="openRdv = false"
              class="p-3 px-6 py-3 mr-2 text-indigo-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-indigo-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              @click="openRdv = false"
              class="px-6 py-3 font-medium tracking-wide text-black bg-cyan-400 rounded-md hover:bg-cyan-200 focus:outline-none"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Modal Ajouter Or-->
    <div
      v-if="openOr"
      :class="`modal ${
        !openOr && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openOr = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 78rem; max-height: 42rem"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-black cursor-pointer modal-close"
        >
          <svg
            class="text-black fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Ajouter OR</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openOr = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div>
            <div
              class="flex mt-4 text-gray-700 content bg-sky-50 text-black border border-b-black"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <label class="text-xm">Client</label>
              <div class="flex ml-2 rounded-md shadow-sm">
                <div class="">
                  <select
                    v-model="refclient"
                    class="block w-full h-full px-4 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value="DV" id="DV">DV</option>
                    <option value="PRO" id="PRO">PRO</option>
                  </select>
                </div>

                <div class="ml-2">
                  <select
                    v-model="idclient"
                    class="block w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value="Divers" id="Divers">Divers</option>
                    <option value="Professionnel" id="Professionnel">
                      Professionnel
                    </option>
                  </select>
                </div>
              </div>

              <label class="ml-6 text-xm">O.R n°</label>
              <div class="flex ml-2 rounded-md shadow-sm">
                <div class="relative">
                  <input
                    v-model="ornumber"
                    class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                </div>
              </div>

              <label class="ml-6 text-xm">du</label>
              <div class="flex ml-2 rounded-md shadow-sm">
                <div class="relative">
                  <input
                    id="dateDuJour"
                    v-model="dateDuJour"
                    type="date"
                    class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                </div>
              </div>

              <div class="flex-col ml-28">
                <div class="flex my-2">
                  <label class="text-xm">Total HT</label>
                  <div class="ml-4 rounded-md shadow-sm">
                    <div class="relative">
                      <input
                        v-model="totalht"
                        class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="flex my-2">
                  <label class="text-xm">Total TVA</label>
                  <div class="ml-2 rounded-md shadow-sm">
                    <div class="relative">
                      <input
                        v-model="totaltva"
                        class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="flex my-2">
                  <label class="text-xm">Total TTC</label>
                  <div class="ml-2.5 rounded-md shadow-sm">
                    <div class="relative">
                      <input
                        v-model="totalttc"
                        class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container tab-pane active">
            <div
              id="IdentificationClient"
              class="grid grid-cols-2 divide-x divide-black content bg-sky-50 border border-b-black"
            >
              <div
                id="form-left-InfoClient"
                class="flex flex-col w-full py-4 px-4"
              >
                <div class="flex mt-4 text-black">
                  <label class="text-xs mr-8 py-2">Nom</label>

                  <div class="flex relative rounded-md shadow-sm">
                    <input
                      v-model="nomduclient"
                      size="55"
                      class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                  </div>
                </div>

                <div class="flex mt-4 text-black">
                  <label class="text-xs mr-4 py-2">Adresse</label>

                  <div class="flex relative rounded-md shadow-sm">
                    <textarea
                      type="text"
                      cols="53"
                      v-model="adresseduclient"
                      class="block w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                    </textarea>
                  </div>
                </div>

                <div class="flex my-4 text-black">
                  <label class="text-xs mr-8 py-2">Pays</label>

                  <div class="flex ml-0.5 relative rounded-md shadow-sm">
                    <input
                      v-model="codepays"
                      class="block focus:outline-2 outline-sky-300 w-24 h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                    <input
                      v-model="pays"
                      size="43"
                      class="ml-2 block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                  </div>
                </div>
              </div>

              <div id="form-right-id" class="flex flex-col py-4 px-4">
                <div class="flex mt-4 text-black">
                  <label class="text-xs mr-4 py-2">Immatr.</label>

                  <div class="flex relative rounded-md shadow-sm">
                    <input
                      v-model="Immatriculation"
                      size="60"
                      class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                  </div>
                </div>

                <div class="flex">
                  <div class="flex mt-4 text-black">
                    <label class="text-xs mr-10 py-2">Tél.</label>

                    <div class="flex relative rounded-md shadow-sm">
                      <input
                        v-model="telephone"
                        size="50"
                        class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>

                  <div class="flex ml-6 mt-4 text-black">
                    <label class="text-xs mr-2 py-2">Tél.2</label>

                    <div class="flex relative rounded-md shadow-sm">
                      <input
                        v-model="telephone2"
                        size="50"
                        class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>

                  <div class="flex ml-6 mt-4 text-black">
                    <label class="text-xs mr-2 py-2">Fax</label>

                    <div class="flex relative rounded-md shadow-sm">
                      <input
                        v-model="fax"
                        size="50"
                        class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="flex mt-4 text-black">
                  <label class="text-xs mr-6 py-2">E-mail</label>

                  <div class="flex relative rounded-md shadow-sm">
                    <input
                      type="email"
                      v-model="email"
                      size="55"
                      class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div id="EnteteOr">
              <div
                class="grid grid-cols-2 divide-x divide-black content bg-sky-50 border"
              >
                <div id="form-left-id" class="flex flex-col py-4 px-4">
                  <div class="flex mt-4 text-black">
                    <label class="text-xs mr-4 py-2">Type de document</label>

                    <div class="flex ml-1 relative rounded-md shadow-sm">
                      <input
                        v-model="Or"
                        size="45"
                        placeholder="Ordre de réparation"
                        disabled="disabled"
                        class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>

                  <div class="flex mt-4 text-black">
                    <label class="text-xs pr-4 ml-0.5 py-2">Vendeur</label>

                    <div class="flex ml-14 relative rounded-md shadow-sm">
                      <input
                        v-model="refvendeur"
                        class="block focus:outline-2 outline-sky-300 w-24 h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                      <input
                        v-model="idvendeur"
                        size="33"
                        class="block focus:outline-2 outline-sky-300 ml-2 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>

                  <div class="flex mt-4 text-black">
                    <label class="text-xs pr-4 py-2">Service</label>

                    <div class="flex ml-16 relative rounded-md shadow-sm">
                      <input
                        v-model="refservice"
                        class="block focus:outline-2 outline-sky-300 w-24 h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                      <input
                        v-model="idservice"
                        size="33"
                        class="block focus:outline-2 outline-sky-300 ml-2 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>

                  <div class="flex mt-4 text-black">
                    <label class="text-xs pr-4 ml-0.5 py-2">Réf. client</label>

                    <div class="flex ml-12 relative rounded-md shadow-sm">
                      <input
                        v-model="refclient"
                        class="block focus:outline-2 outline-sky-300 w-24 h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                      <input
                        v-model="idclient"
                        size="33"
                        class="block focus:outline-2 outline-sky-300 ml-2 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>

                  <div class="flex mt-4 text-black">
                    <label class="text-xs pr-4 mr-8 py-2">Commercial</label>

                    <div class="flex ml-1.5 relative rounded-md shadow-sm">
                      <input
                        v-model="Commercial"
                        size="45"
                        class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>

                <div id="form-right-id" class="flex flex-col py-4 px-4">
                  <div class="flex flex-col mt-4 text-black">
                    <label class="text-xs pr-4 py-2">Travaux à effectuer</label>

                    <div class="flex relative rounded-md shadow-sm">
                      <textarea
                        type="text"
                        cols="53"
                        v-model="travauxaeffectuer"
                        class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                      </textarea>
                    </div>
                  </div>

                  <div class="flex flex-col mt-4 text-black">
                    <label class="text-xs pr-4 py-2">Commentaire client</label>

                    <div class="flex relative rounded-md shadow-sm">
                      <textarea
                        type="text"
                        cols="53"
                        v-model="commentaireclient"
                        class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-col mt-2 justify-end">
                <div
                  class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
                >
                  <div
                    class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
                  >
                    <table class="min-w-full">
                      <thead class="tableHEADTR">
                        <tr>
                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                          >
                            Date RDV
                          </th>
                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                          >
                            Travaux
                          </th>
                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                          >
                            Début
                          </th>
                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                          >
                            Fin
                          </th>
                        </tr>
                      </thead>

                      <tbody class="bg-white tableBODY">
                        <tr
                          v-for="(articleAct, index) in actionArticle"
                          :key="index"
                          class="tableHEADTR"
                        >
                          <td
                            class="px-6 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                          >
                            <div class="flex items-center">
                              <div class="ml-4">
                                <div class="text-sm leading-5 text-gray-900">
                                  {{ articleAct.Ref_fournisseur }}
                                </div>
                              </div>
                            </div>
                          </td>

                          <td
                            class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                            style="overflow: none"
                          >
                            <div class="text-sm leading-5 text-gray-900">
                              {{ articleAct.Code_marque }}
                            </div>
                          </td>

                          <td
                            class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                          >
                            <input
                              class="focus:outline-2 outline-sky-300"
                              style="width: 50%"
                              type="number"
                              :placeholder="articleAct.Qte_mini"
                            />
                          </td>

                          <td
                            class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                          >
                            0,00 €
                          </td>

                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                          >
                            {{ parseFloat(articleAct.Prix_euro).toFixed(2) }} €
                          </td>
                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                          >
                            <input
                              class="focus:outline-2 outline-sky-300"
                              type="number"
                              style="width: 30%"
                              placeholder="0"
                              step="5"
                            />
                            %
                          </td>
                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                          >
                            {{ parseFloat(articleAct.Prix_euro).toFixed(2) }} €
                          </td>
                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                          >
                            {{ parseFloat(articleAct.Prix_euro).toFixed(2) }} €
                          </td>
                          <td
                            class="px-8 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                          >
                            <span
                              class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 rounded-full"
                              style="cursor: pointer"
                              @click="suppactionArticle(articleAct._id)"
                            >
                              <span class="material-icons-round">
                                backspace
                              </span>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="Lignes">
            <div class="flex flex-col mt-2 justify-end">
              <div
                class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
              >
                <div
                  class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
                >
                  <table class="min-w-full">
                    <thead
                      class="tableHEADTR"
                      style="min-width: 240rem; max-height: 48rem"
                    >
                      <tr>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          N°
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Equiv.
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Code article
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Libellé
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Qté commandée
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Qté à livrer
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Qté unitaire brut TTC
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          T1
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          T2
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Prix unitaire net TTC
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Montant TTC
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Prix achat
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Libellé complémentaire
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Poids unitaire
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Poids total
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          N° taux TVA
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Type de vente
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Date de livraison
                        </th>
                      </tr>
                    </thead>

                    <tbody class="bg-white tableBODY">
                      <tr
                        v-for="(articleAct, index) in actionArticle"
                        :key="index"
                        class="tableHEADTR"
                      >
                        <td
                          class="px-6 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div class="text-sm leading-5 text-gray-900">
                                {{ articleAct.Ref_fournisseur }}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td
                          class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                          style="overflow: none"
                        >
                          <div class="text-sm leading-5 text-gray-900">
                            {{ articleAct.Code_marque }}
                          </div>
                        </td>

                        <td
                          class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          <input
                            class="focus:outline-2 outline-sky-300"
                            style="width: 50%"
                            type="number"
                            :placeholder="articleAct.Qte_mini"
                          />
                        </td>

                        <td
                          class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          0,00 €
                        </td>

                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          {{ parseFloat(articleAct.Prix_euro).toFixed(2) }} €
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          <input
                            class="focus:outline-2 outline-sky-300"
                            type="number"
                            style="width: 30%"
                            placeholder="0"
                            step="5"
                          />
                          %
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          {{ parseFloat(articleAct.Prix_euro).toFixed(2) }} €
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          {{ parseFloat(articleAct.Prix_euro).toFixed(2) }} €
                        </td>
                        <td
                          class="px-8 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          <span
                            class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 rounded-full"
                            style="cursor: pointer"
                            @click="suppactionArticle(articleAct._id)"
                          >
                            <span class="material-icons-round">
                              backspace
                            </span>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="openOr = false"
              class="p-3 px-6 py-3 mr-2 text-indigo-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-indigo-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              @click="openOr = false"
              class="px-6 py-3 font-medium tracking-wide text-black bg-cyan-400 rounded-md hover:bg-cyan-200 focus:outline-none"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex flex-row justify-end mt-2 border-b border-b-black py-2 pr-4"
    >
      <button
        class="px-2 py-2 w-24 font-medium shadow tracking-wide text-black bg-sky-400 border border-black rounded-md hover:bg-sky-300 focus:outline-none btn btn-primary"
        @click="openRdv = true"
      >
        Nouveau RDV
      </button>

      <button
        class="ml-6 px-2 py-2 w-24 font-medium shadow tracking-wide text-black bg-sky-400 border border-black rounded-md hover:bg-sky-300 focus:outline-none btn btn-primary"
        @click="openOr = true"
      >
        Ajouter OR
      </button>
    </div>

    <div id="divToPrint" class="content">
      <h2
        class="w-36 mt-2 pl-2 py-1 shadow rounded-t-md content text-black bg-sky-50 border border-b-black"
      >
        Recherche
      </h2>

      <div
        id="Recherche"
        class="flex w-full py-4 px-4 rounded-tr-lg shadow grid grid-cols-3 content bg-sky-50 space-x-8"
      >
        <div class="text-black">
          <label class="text-xs py-2">N° OR</label>

          <div class="flex relative rounded-md shadow-sm">
            <input
              v-model="numOr"
              class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
            />
          </div>
        </div>

        <div class="text-black">
          <label class="text-xs py-2">Nom client</label>

          <div class="flex relative rounded-md shadow-sm">
            <input
              v-model="rechercheNom"
              class="block w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
            />
          </div>
        </div>

        <div class="text-black">
          <label class="text-xs py-2">Date OR</label>

          <div class="flex relative rounded-md shadow-sm">
            <input
              type="date"
              v-model="rechercheDate"
              class="block focus:outline-2 outline-sky-300 w-full h-full px-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
            />
          </div>
        </div>
      </div>

      <div
        class="flex justify-end content rounded-b-lg shadow-b-md bg-sky-50 pb-3 px-4"
      >
        <button
          @click="getOr(), getRechercheNom(), getRechercheDate()"
          class="px-2 py-2 w-32 font-medium tracking-wide border border-black text-black bg-sky-400 rounded-md hover:bg-sky-300 focus:outline-none btn btn-primary"
        >
          Rechercher
        </button>
      </div>
    </div>

    <div
      class="mt-8 grid grid-col-3"
      style="display: flex; align-items: center; justify-content: center"
    >
      <tr class="flex space-x-6">
        <th
          class="w-36 flex items-center justify-center rounded px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border border-gray-200"
        >
          N° OR
        </th>
        <th
          class="w-64 flex items-center justify-center rounded px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border border-gray-200"
        >
          Nom client
        </th>
        <th
          class="w-36 flex items-center justify-center rounded px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border border-gray-200"
        >
          Date OR
        </th>
      </tr>
    </div>

    <div
      class="mt-4 flex"
      style="display: flex; align-items: center; justify-content: center"
    >
      <tr v-for="(o, index) in Or" :key="index" class="divide-x divide-black">
        <td class="px-6 py-4 border-gray-200 whitespace-nowrap">
          <div class="text-sm leading-5 text-gray-900">
            {{ o.Numero }}
          </div>
        </td>
        <td class="px-6 py-4 border-gray-200 whitespace-nowrap">
          <div class="text-sm leading-5 text-gray-900">
            {{ o.Name }}
          </div>
        </td>
        <td class="px-6 py-4 border-gray-200 whitespace-nowrap">
          <div class="text-sm leading-5 text-gray-900">
            {{ o.Date }}
          </div>
        </td>
      </tr>
    </div>

    <button
      class="my-12 px-6 py-2 mt-12 font-medium shadow tracking-wide text-black bg-sky-400 rounded-md hover:bg-sky-300 focus:outline-none btn btn-primary"
      @click="openPdf()"
    >
      Exporter en pdf
    </button>
  </div>
</template>

<script lang="js">
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "MyRetour",
    data: () => ({
      infosRetour: [],
      Or:[],
      numOr:"",
      rechercheNom:"",
      rechercheDate: "",
      openSave:false,
      openRdv:false,
      openOr:false,
      showArticleList:false,
      Clients:[],
      clientChosed:'',
      articleSearch:'',
      foundArticle:[],
      documentType:'',
      titreDocument:'',
      noteDocument:"",
      historique:[],
      historiqueShow:false,
      refclient:"",
      idclient:"",
      ornumber:"",
      dateDuJour:"",
      totalht:"",
      totaltva:"",
      totalttc:"",
      refvendeur:"",
      idvendeur:"",
      refservice:"",
      idservice:"",
    }),
    computed:{
    ...mapGetters(["actionArticle", "user"]),
    setTotal(){
      var total = 0.00;
      this.actionArticle.forEach(element => {
        total = total + parseFloat(element.Prix_euro);
      });
      return total;
    },
    setTotalTTC(){
      return this.setTotal + (this.setTotal * 0.2);

    },
    setQuantity(){
      var qty = 0;
      this.actionArticle.forEach(element => {
        qty = qty + parseFloat(element.Qte_multiple);
      });
      return qty;
    }
  },
    methods: {
    ...mapActions([
      "addactionArticle",
      "suppactionArticle",
      "clearactionArticle",
      "updateactionArticle",
    ]),
    handlerArticle(data) {
      this.addactionArticle(data);
      this.showArticleList = false;
      // this.addProduitComponent = false;
    },
    getToday(){
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();

      today = dd + '/' + mm + '/' + yyyy + ' ' + hour + ':' + minutes + ':' + seconds;

      this.dateDuJour=today;
    },
    getOr(){
      axios.get(`${process.env.VUE_APP_API}/or/getone/${this.numOr}`).then((response)=>{
        this.Or = response.data;

      });
    },
    getRechercheNom(){
      axios.get(`${process.env.VUE_APP_API}/or/getone/${this.rechercheNom}`).then((response)=>{
        this.Or = response.data;

      });
    },
    getRechercheDate(){
      axios.get(`${process.env.VUE_APP_API}/or/getone/${this.rechercheDate}`).then((response)=>{
        this.Or = response.data;

      });
    },
    saveDocument() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();

      today = dd + '/' + mm + '/' + yyyy + ' ' + hour + ':' + minutes + ':' + seconds;
      switch (this.documentType) {
        case 'Devis':
          axios.post(`${process.env.VUE_APP_API}/devis`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        case 'Commande':
          axios.post(`${process.env.VUE_APP_API}/commandes`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        case 'Bon de livraison':
          axios.post(`${process.env.VUE_APP_API}/bdl`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        case 'Consigne':
          axios.post(`${process.env.VUE_APP_API}/consigne`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        case 'Or':
          axios.post(`${process.env.VUE_APP_API}/or`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        case 'Avoir':
          axios.post(`${process.env.VUE_APP_API}/avoir`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        case 'Facturation':
          axios.post(`${process.env.VUE_APP_API}/facture`,{Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
          break;
        default:
          break;
      }
      axios.post(`${process.env.VUE_APP_API}/comptoir`,{Type:this.documentType, Name:this.user.username,Date:today, Numero:123, TotalPrice:this.setTotal, Client:this.clientChosed, IsProcessed:false, Note:this.noteDocument, Articles:this.actionArticle});
      this.clearactionArticle();
      this.clientChosed = '';
      this.articleSearch = '';
    },
    getHistorique(){
      axios
        .post(`${process.env.VUE_APP_API}/comptoir/getone`,{uid:this.clientChosed})
        .then((response) => {
          this.historique = response.data;
        });
    },
    setHistoriqueFile(historiquedata){
      this.updateactionArticle(historiquedata);
    },
      getInfosRetour() {
        axios
        .get(`${process.env.VUE_APP_API}/retour`)
        .then((response) => {
          this.infosRetour = response.data;
        });
    },
    },
    mounted() {
    this.getInfosRetour();
    this.getToday();
  },
}
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
.tableTDTH {
  text-align: left;
  padding: 8px;
}

.tableBODY {
  display: block;
  height: 51vh;
  overflow: auto;
}
.tableHEADTR {
  display: table;
  width: 100%;
  table-layout: fixed;
}
</style>
