<template>
  <div class="flex flex-col mt-0.5">
    <div class="wrap">
      <button class="bouton">
        <!-- <i class="ri-side-bar-line"></i> -->
        <img id="imgCarcat" src="@/assets/CarCat.png" alt="" />
      </button>
      <ul class="list">
        <li class="item1" @click="openCarCat()">
          <a class="link1">
            <i class="ri-book-read-line"></i>
          </a>
        </li>
        <li class="item1" @click="closeCarCat()">
          <a class="link1">
            <i class="ri-shopping-cart-line"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "remixicon/fonts/remixicon.css";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  computed: {
    ...mapGetters(["carcatToken", "carCatReference"]),
  },
  methods: {
    ...mapActions([
      "clearcarcattoken",
      "addpaniercarcat",
      "setcarcatlinks",
      "storerefreshcarcattoken",
    ]),
    gety() {
      const boutonEl = document.querySelector(".bouton");
      const listEl = document.querySelector(".list");
      //   const i = document.querySelector(".list");

      const toggleOptions = () => {
        const wrapEl = document.querySelector(".wrap");

        wrapEl.classList.toggle("active");
      };

      boutonEl.addEventListener("mouseover", toggleOptions);
      listEl.addEventListener("mouseleave", toggleOptions);
    },
    openCarCat() {
      axios
        .post(
          `https://lkq-europe-prod.apigee.net/gms-api-public-iframe-routes-fr/iframe/v2/ticket`,
          {
            IDToken: this.carcatToken.id_token,
            ReferenceIdentifier:
              "referenceVekteur" + Math.floor(Math.random() * 1000000000),
            // OrderCreationBehaviour: "force_new_order",
            // ShowNavigation: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.carcatToken.access_token}`,
              "X-api-key": "IbW4A7bqa447M2c7WhFwagVtbMjL9DLd",
            },
          }
        )
        .then((result) => {
          this.setcarcatlinks({
            link: result.data.Data.TicketURL,
            ref: result.data.Data.ReferenceIdentifier,
          });
          // this.openCarCatModal = true;
          this.$router.push("/carcat");
        })
        .catch((error) => {
          this.refreshCarCatToken();
        });
    },
    async refreshCarCatToken() {
      await axios
        .post(
          `https://lkq-carsys-fr-production.auth.eu-central-1.amazoncognito.com/oauth2/token`,
          `grant_type=refresh_token&refresh_token=${this.carcatToken.refresh_token}&client_id=1q5491gklchf6dil2njt62d77f&redirect_uri=https://cl.vekteur.fr`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Basic ${btoa(
                "1q5491gklchf6dil2njt62d77f:k1m3ri382088l9qo9s04mpadn7if35vqmea0k3hreauj4psa4p2"
              )}`,
            },
          }
        )
        .then((result) => {
          this.storerefreshcarcattoken(result.data);
          this.openCarCat();
        })
        .catch((error) => {
          console.log(error);
          this.clearcarcattoken();
        });
    },
    async closeCarCat() {
      await axios
        .post(
          `https://lkq-europe-prod.apigee.net/gms-api-public-iframe-routes-fr/iframe/retrieve-orders`,
          {
            IDToken: this.carcatToken.id_token,
            ReferenceIdentifier: this.carCatReference,
            PerPage: 1000,
            Page: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.carcatToken.access_token}`,
              "X-api-key": "IbW4A7bqa447M2c7WhFwagVtbMjL9DLd",
            },
          }
        )
        .then((result) => {
          this.addpaniercarcat({
            lines: result.data.Data.Workorders[0].Lines,
          });
        })
        .catch((error) => {
          this.clearcarcattoken();
        });
    },
  },
  mounted() {
    this.gety();
  },
};
</script>

<style>
.wrap {
  position: relative;
}
.wrap :is(.bouton) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #fb923c;
  color: #ffffff;
  border-radius: 50%;
  z-index: 20;
}
.wrap :is(.link1) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #0f7325;
  color: #ffffff;
  border-radius: 50%;
  z-index: 20;
  margin-left: -80px;
}
.wrap :is(.bouton, .link1):hover {
  background-color: #3c894d;
}
.bouton {
  position: absolute;
  border: none;
  cursor: pointer;
  z-index: 30;
  transition: outline 0.25s;
}
.active .bouton,
.bouton:hover {
  border-color: #000000;
  outline: 2px solid #ffffff;
  outline-offset: -4px;
}
.item1 {
  cursor: pointer;
  position: absolute;
  list-style: none;
  transition: transform 0.5s;
  transition-delay: var(--d);
  margin-left: 80px;
}
.active .list {
  cursor: pointer;
  position: absolute;
  left: -45px;
  width: 120px;
  height: 40px;
  top: -5px;
  background-color: rgb(18, 186, 252);
  border-radius: 30px;
}
.active .item1 {
  cursor: pointer;
  position: absolute;
  left: 45px;
  top: 5px;
  background-color: rgb(255, 140, 0);
}
.wrap.active .item1:nth-child(1) {
  transform: translateX(-35px);
}
.wrap.active .item1:nth-child(2) {
  transform: translateX(35px);
}
</style>
