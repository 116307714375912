<template>
  <div
    v-if="openRetour"
    :class="`modal ${
      !openRetour && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openRetour = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 200rem; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Retour article</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openRetour = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div>
          <div
            class="inline-block h-fit min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg overflow-y-auto"
          >
            <table class="min-w-full" style="width: 65rem">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Réf.
                  </th>
                  <th
                    class="w-80 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Description / Marque
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Qté
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Px final
                  </th>
                  <th
                    class="border-r px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Total
                  </th>
                  <th
                    class="border-r px-6 py-3 text-xs font-medium leading-4 tracking-wider text-blue-600 uppercase text-center border-b border-gray-200 bg-gray-50"
                  >
                    Qté déjà retourné
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  ></th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr
                  v-for="(retourArticlesS, indexR) in retourSelected.Articles"
                  :key="indexR"
                >
                  <!-- réference -->
                  <td
                    class="px-2 border-b border-gray-200 whitespace-nowrap tableTDTH w-44"
                  >
                    <div class="flex flex-col space-y-2">
                      <div>{{ retourArticlesS.Ref_fournisseur }}</div>
                      <!-- <div v-if="retourSelected.Articles[indexR + 1]">
                        <div
                          v-if="
                            retourSelected.Articles[
                              indexR + 1
                            ].Ref_fournisseur.startsWith('C :')
                          "
                        >
                          <span
                            class="text-xs p-1 bg-orange-400 text-white rounded cursor-pointer"
                            @click="showConsigne = !showConsigne"
                            >retourner la consigne</span
                          >
                          <div
                            class="absolute z-20 p-1 bg-white rounded shadow"
                            v-show="showConsigne"
                          >
                            <div class="flex flex-col space-y-1">
                              <div class="flex flex-row space-x-1 items-center">
                                <span>Ref :</span>
                                <span>{{
                                  retourSelected.Articles[indexR + 1]
                                    .Ref_fournisseur
                                }}</span>
                              </div>
                              <div class="flex flex-row space-x-1 items-center">
                                <span>Montant de la consigne :</span
                                ><span
                                  >{{
                                    retourSelected.Articles[indexR + 1]
                                      .Prix_euro
                                  }}
                                  €</span
                                >
                              </div>
                              <div class="flex flex-row space-x-1 items-center">
                                <span>Qté :</span>
                                <input
                                  class="w-12 focus:outline-2 outline-sky-300 border border-green-600"
                                  type="number"
                                  v-model="qtyReturned[indexR + 1]"
                                />
                              </div>
                              <div class="flex justify-end">
                                @click="
                                    addRetour(
                                      retourSelected.Articles[indexR + 1],
                                      indexR + 1
                                    )
                                  " 
                                <button
                                  class="material-icons-round p-1 bg-blue-500 text-white rounded"
                                >
                                  shopping_cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </td>

                  <!-- description / marque -->
                  <td
                    class="w-80 px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="text-sm leading-5 whitespace-normal w-fit">
                      {{ retourArticlesS.Description }}
                    </div>
                    <div class="text-xs leading-5 text-gray-900">
                      {{ retourArticlesS.Code_marque }}
                    </div>
                  </td>

                  <!-- quantité -->
                  <td
                    class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      disabled
                      class="m-auto focus:outline-2 outline-sky-300 border border-green-600"
                      style="width: 6vh"
                      type="number"
                      :value="retourArticlesS.quantity"
                    />
                  </td>

                  <!-- prix vente -->
                  <td
                    class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ parseFloat(retourArticlesS.Prix_euro).toFixed(2) }} €
                  </td>

                  <!-- remise / prix net -->
                  <td
                    class="px-2 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex text-sm leading-5 text-gray-900">
                      <div class="flex">
                        <p
                          v-if="
                            retourArticlesS.PrixFournisseurType != 'prixnet'
                          "
                        >
                          {{ financial(retourArticlesS.remise) }} %
                        </p>
                        <p v-else>{{ retourArticlesS.PrixFournisseurType }}</p>
                      </div>
                    </div>
                  </td>

                  <!-- prix final -->
                  <td
                    class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex space-x-1 text-sm leading-5 text-gray-900">
                      <p
                        v-if="retourArticlesS.PrixFournisseurType != 'prixnet'"
                      >
                        {{
                          financial(
                            retourArticlesS.Prix_euro -
                              retourArticlesS.Prix_euro *
                                (retourArticlesS.remise / 100)
                          )
                        }}
                      </p>
                      <p v-else>
                        {{ financial(retourArticlesS.Prix_vente) }}
                      </p>

                      <p>€</p>
                    </div>
                  </td>

                  <!-- total -->
                  <td
                    class="px-6 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex space-x-1 text-sm leading-5 text-gray-900">
                      <span
                        v-if="retourArticlesS.PrixFournisseurType != 'prixnet'"
                      >
                        {{
                          financial(
                            (retourArticlesS.Prix_euro -
                              retourArticlesS.Prix_euro *
                                (retourArticlesS.remise / 100)) *
                              retourArticlesS.quantity
                          )
                        }}
                      </span>
                      <span v-else>
                        {{
                          financial(
                            retourArticlesS.Prix_vente *
                              retourArticlesS.quantity
                          )
                        }}
                      </span>
                      <span>€</span>
                    </div>
                  </td>

                  <!-- quantité déjà retourné -->
                  <td
                    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      disabled
                      class="mx-auto flex justify-center focus:outline-2 outline-sky-300 border border-blue-600"
                      style="width: 6vh"
                      type="number"
                      :value="retourArticlesS.quantityReturned"
                    />
                  </td>

                  <!-- ajout panier -->
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                  >
                    <!-- <div
                      class="flex justify-end space-x-4 py-4"
                    >
                      <input
                        class="focus:outline-2 outline-sky-300 border border-red-600"
                        style="width: 6vh"
                        type="number"
                        min="0"
                        :placeholder="
                          retourArticlesS.quantity -
                          retourArticlesS.quantityReturned
                        "
                        :max="
                          retourArticlesS.quantity -
                          retourArticlesS.quantityReturned
                        "
                        v-model="qtyReturned[indexR]"
                      />
                      <div v-if="retourSelected.Articles[
                        indexR + 1
                      ]">
                        <button
                          v-if="
                            retourSelected.Articles[
                              indexR + 1
                            ].Ref_fournisseur.startsWith('C :') &&
                            retourArticlesS.quantity -
                              retourArticlesS.quantityReturned >
                              0
                          "
                          class="material-icons-round text-xs px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
                        >
                          shopping_cart
                        </button><button
                        v-else-if="
                          retourArticlesS.quantity -
                            retourArticlesS.quantityReturned >
                          0
                        "
                        class="material-icons-round text-xs px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
                      >
                        shopping_cart
                      </button>
                      </div>
                      <button
                        v-else-if="
                          retourArticlesS.quantity -
                            retourArticlesS.quantityReturned >
                          0
                        "
                        class="material-icons-round text-xs px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
                      >
                        shopping_cart
                      </button>
                    </div> -->
                    <input
                      class="focus:outline-2 outline-sky-300 border border-red-600"
                      style="width: 6vh"
                      type="number"
                      min="0"
                      :placeholder="
                        retourArticlesS.quantity -
                        retourArticlesS.quantityReturned
                      "
                      :max="
                        parseInt(
                          retourArticlesS.quantity -
                            retourArticlesS.quantityReturned
                        )
                      "
                      v-model="qtyReturned[indexR]"
                    />
                    <button
                      class="ml-4 material-icons-round text-xs px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
                      @click="
                        addReturnCart(
                          retourArticlesS,
                          retourSelected.Date,
                          qtyReturned[indexR],
                          retourSelected._id,
                          retourSelected.type,
                          retourSelected.Numero,
                          retourArticlesS.Code_EAN,
                          indexR
                        )
                      "
                      :disabled="loadingReturnCart"
                    >
                      shopping_cart
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <span v-show="showErrorMsgReturn" class="text-red-600"
            >La quantité à retourner ne peut pas être supérieur à la quantité
            acheté, ni negative !</span
          >
        </div>

        <!--Footer-->
        <div class="flex justify-end pt-2">
          <button
            @click="openRetour = false"
            class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col space-y-4">
    <div class="flex flex-row justify-between items-center">
      <div>
        <button
          @click="$router.back()"
          class="flex flex-row space-x-2 items-center bg-blue-400 text-white px-2 py-1 rounded-md"
        >
          <span class="material-icons-outlined text-sm"> arrow_back_ios </span>
          <span>retour</span>
        </button>
      </div>
      <div>
        <!-- TODO : ajouter des boutons -->
      </div>
    </div>
    <div class="flex justify-between">
      <searchInput
        placeholder="Rechercher un article dans les BL"
        @searchWord="search($event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />
      <span class="cart-icon" @click="pushToPanierRetour()">
        <button class="material-icons-round">shopping_cart</button>
        <span class="cart-item-count">{{ articleInCart }}</span></span
      >
    </div>

    <div>
      <div
        class="inline-block h-fit min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg overflow-y-auto"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Doc n°
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Date
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Client
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Créé par
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Prix total
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Référence client
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr
              v-for="(historiqueFile, indexHistorique) in historique"
              :key="indexHistorique"
              @click="attachRetour(historiqueFile)"
              class="cursor-pointer hover:bg-orange-200 hover:bg-opacity-25"
            >
              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ historiqueFile.type }} N°
                {{ historiqueFile.Numero }}
              </td>
              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ getFRDate(historiqueFile.Date) }}
              </td>
              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ historiqueFile.Client }}
              </td>
              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ historiqueFile.Name }}
              </td>
              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ parseFloat(historiqueFile.TotalPrice).toFixed(2) }} €
              </td>
              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ historiqueFile.Note }}
              </td>

              <!-- <td class="px-6 py-3 border-b border-gray-200 bg-gray-50"></td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import searchInput from "@/components/searchInput.vue";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { getFrDate } from "@/hooks/tools/date.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      tabRetourHistorique: false,
      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,
      historique: [],
      retourSelected: [],
      retourArticle: [],
      openRetour: false,
      showErrorMsgReturn: false,
      qtyReturned: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ],
      showConsigne: false,
      articleInCart: 0,
      articleExist: false,
      loadingReturnCart: false,
    };
  },
  components: {
    searchInput,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    async search(searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
      } else {
        this.searchActive = true;
        this.searchInProgress = true;

        await axios
          .post(`${process.env.VUE_APP_API}/bdl/getbyclientretour`, {
            uid: {
              client: this.user.proprietaire,
              owner: this.user.plateform,
              article: searchTerm,
              quantity: 1,
            },
          })
          .then((response) => {
            this.historique = response.data.historique;
            this.retourArticle = response.data.retourArticle;
            this.searchInProgress = false;
          })
          .catch((error) => {
            this.searchInProgress = false;
            this.searchCatchError = true;
          });

        this.historique = this.historique.filter(
          (h) => parseFloat(h.TotalPrice) >= 0
        );
      }
    },
    async attachRetour(historiqueFileSelected) {
      this.retourSelected = [];
      this.retourSelected = historiqueFileSelected;
      await this.retourSelected.Articles.forEach((element, ind) => {
        this.qtyReturned[ind] = 0;
        if (!element.quantityReturned || element.quantityReturned == "") {
          element.quantityReturned = 0;
        }
      });
      this.openRetour = true;
    },
    pushToPanierRetour() {
      this.$router.push("./panierRetour");
    },
    async addReturnCart(
      data,
      dateBL,
      quantity,
      idBL,
      typeDoc,
      numBL,
      ean,
      index
    ) {
      this.loadingReturnCart = true;
      await this.checkItemExist(idBL, ean);
      if (this.articleExist == false) {
        if (
          parseInt(quantity) >
            parseInt(data.quantity - data.quantityReturned) ||
          parseInt(quantity) <= 0
        ) {
          this.toast.error("Quantité invalide !", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        } else {
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();
          var hour = today.getHours();
          var seconds = today.getSeconds();
          var minutes = today.getMinutes();
          today =
            dd +
            "/" +
            mm +
            "/" +
            yyyy +
            " " +
            hour +
            ":" +
            minutes +
            ":" +
            seconds;
          var tab = [];
          if (
            this.retourSelected.Articles[index + 1] &&
            this.retourSelected.Articles[index + 1].Ref_fournisseur.startsWith(
              "C :"
            )
          ) {
            tab.push({
              ...data,
              raisonRetour: "",
              raisonRetourAutre: "",
              dateBL: dateBL,
              typeDoc: typeDoc,
              numBL: numBL,
              quantity: -quantity,
              idBL: idBL,
              requestedQuantity: -quantity,
            });
            axios
              .post(`${process.env.VUE_APP_API}/panierRetour`, {
                plateform: this.user.plateform, // plateform
                Client: this.user.proprietaire, // Client
                Name: this.user.username, // username
                // Date: today,
                clientInfo: this.retourSelected.clientInfo,
                reglement: [],
                Note: "",
                Transport: 0,
                itemToReturn: tab,
              })
              .then(() => {
                tab = [];
                tab.push({
                  ...this.retourSelected.Articles[index + 1],
                  raisonRetour: "",
                  raisonRetourAutre: "",
                  dateBL: dateBL,
                  typeDoc: typeDoc,
                  numBL: numBL,
                  quantity: -quantity,
                  idBL: idBL,
                  requestedQuantity: -quantity,
                });
                axios
                  .post(`${process.env.VUE_APP_API}/panierRetour`, {
                    plateform: this.user.plateform, // plateform
                    Client: this.user.proprietaire, // Client
                    Name: this.user.username, // username
                    // Date: today,
                    clientInfo: this.retourSelected.clientInfo,
                    reglement: [],
                    Note: "",
                    Transport: 0,
                    itemToReturn: tab,
                  })
                  .then(() => {
                    this.countArticleInPanier();
                    this.toast.success("Ajouté au panier !", {
                      position: "bottom-right",
                      timeout: 2000,
                      closeOnClick: true,
                      pauseOnFocusLoss: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 1,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: "button",
                      icon: true,
                      rtl: false,
                    });
                  });
              });
          } else {
            tab.push({
              ...data,
              raisonRetour: "",
              raisonRetourAutre: "",
              dateBL: dateBL,
              typeDoc: typeDoc,
              numBL: numBL,
              quantity: -quantity,
              idBL: idBL,
              requestedQuantity: -quantity,
            });
            axios
              .post(`${process.env.VUE_APP_API}/panierRetour`, {
                plateform: this.user.plateform, // plateform
                Client: this.user.proprietaire, // Client
                Name: this.user.username, // username
                // Date: today,
                clientInfo: this.retourSelected.clientInfo,
                reglement: [],
                Note: "",
                Transport: 0,
                itemToReturn: tab,
              })
              .then(() => {
                this.countArticleInPanier();
                this.toast.success("Ajouté au panier !", {
                  position: "bottom-right",
                  timeout: 2000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 1,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              });
          }
        }
      } else {
        this.toast.error("Ce produit est dans le panier !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      this.loadingReturnCart = false;
    },
    countArticleInPanier() {
      axios
        .post(`${process.env.VUE_APP_API}/panierRetour/count`, {
          plateform: this.user.plateform, // plateform
          Client: this.user.proprietaire, // Client
        })
        .then((response) => {
          this.articleInCart = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async checkItemExist(idBL, ean) {
      await axios
        .post(`${process.env.VUE_APP_API}/panierRetour/checkItemExist`, {
          plateform: this.user.plateform, // plateform
          client: this.user.proprietaire, // Client
          idBL: idBL,
          ean: ean,
        })
        .then((response) => {
          this.articleExist = response.data;
        })
        .catch((error) => {
          this.articleExist = false;
          console.log(error);
        });
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  mounted() {
    this.countArticleInPanier();
  },
};
</script>
<style>
.cart-icon {
  position: relative; /* Pour contrôler la position du nombre d'articles */
}

.cart-icon .material-icons-round {
  font-size: 40px; /* Taille de l'icône de panier */
  color: #007bff; /* Couleur de l'icône */
}

.cart-icon .cart-item-count {
  position: absolute;
  top: -8px; /* Ajustez cette valeur pour positionner le nombre d'articles correctement */
  right: -8px; /* Ajustez cette valeur pour positionner le nombre d'articles correctement */
  background-color: #ff5733; /* Couleur d'arrière-plan du nombre d'articles */
  color: #ffffff; /* Couleur du texte du nombre d'articles */
  border-radius: 50%; /* Pour créer un cercle autour du nombre d'articles */
  width: 24px; /* Largeur du cercle */
  height: 24px; /* Hauteur du cercle */
  font-size: 14px; /* Taille de police du nombre d'articles */
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
