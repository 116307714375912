<template>
  <!-- début modal article -->
  <div
    v-if="openArticle"
    :class="`modal ${
      !openArticle && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openArticle = false"
      class="absolute w-full h-full bg-gray-500 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 72rem; max-height: 60rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between mb-3">
          <p class="text-2xl font-bold">{{ tabDetailArticle.titre }}</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openArticle = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div
          style="max-width: 65rem; max-height: 50rem"
          class="overflow-y-auto"
        >
          <img :src="tabDetailArticle.url" class="h-full w-full" />
        </div>

        <!--Footer-->
        <div class="flex justify-end mt-2">
          <button
            @click="openArticle = false"
            class="p-3 px-6 py-3 mr-2 text-indigo-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-indigo-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            class="px-6 py-3 font-medium tracking-wide material-icons-round text-bluevk rounded-md focus:outline-none"
            @click="ajoutArticlePanier(tabDetailArticle)"
          >
            add_shopping_cart
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal article -->

  <!-- bandeau -->
  <div class="flex flex-row mt-0 bg-bluevk justify-center">
    <div
      class="flex text-white md:text-2xl font-medium flex-row items-baseline p-1 cursor-pointer"
    >
      Historique articles recherchés
    </div>
  </div>

  <!-- Recherche -->
  <div class="flex mt-2">
    <searchInput
      placeholder="Rechercher dans la liste des articles recherchés"
      @searchWord="search(1, 10, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
      class="mt-2"
    />
  </div>

  <table class="min-w-full mt-8">
    <thead>
      <tr>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          Terme recherché
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          Image
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          Ref/EAN
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          Marque
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          Description
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          Stock
        </th>
      </tr>
    </thead>

    <tbody class="bg-white">
      <tr v-for="(art, index) in artTab" :key="index" class="hover:bg-gray-50">
        <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
          <div class="text-sm leading-5 text-bluevk font-medium">
            {{ art.Search }}
          </div>
          <div class="text-sm leading-5 text-gray-900">
            {{ getFRDate(art.Date) }}
          </div>
        </td>

        <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
          <img
            src=""
            alt="indisponible"
            class="my-auto bg-gray-300 rounded-sm ml-1 mr-2 text-xs text-gray-700 items-center"
          />
        </td>

        <td
          v-if="art.Result.articleTab.length > 0"
          class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
        >
          <div
            class="flex justify-between text-sm leading-5 text-orangevk font-medium"
          >
            <h1>{{ art.Result.articleTab[0].article.Ref_fournisseur }}</h1>
            <button
              class="material-icons-round text-green-600 hover:text-green-700"
              style="font-size: 16px"
              title="Ajouter au panier"
              @click="ajoutArticlePanier(art.Result.articleTab[0].article)"
            >
              add_shopping_cart
            </button>
          </div>
          <div class="text-sm leading-5 text-gray-900">
            {{ art.Result.articleTab[0].article.Code_EAN }}
          </div>
        </td>
        <td
          v-else
          class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
        ></td>

        <td
          v-if="art.Result.articleTab.length > 0"
          class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
        >
          <div class="text-sm leading-5 text-gray-900">
            {{ art.Result.articleTab[0].article.Code_marque }}
          </div>
        </td>
        <td
          v-else
          class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
        ></td>

        <td
          v-if="art.Result.articleTab.length > 0"
          class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
        >
          {{ art.Result.articleTab[0].article.Description }}
        </td>
        <td
          v-else
          class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
        ></td>

        <td
          v-if="
            art.Result.articleTab.length > 0 &&
            art.Result.articleTab[0].stock.length > 0
          "
          class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
          title="disponible"
        >
          <div
            v-if="parseInt(art.Result.articleTab[0].stock[0].stock) > 0"
            class="material-icons-round text-sm leading-5 text-green-600 font-medium"
          >
            check_circle
          </div>
          <div
            v-else
            class="material-icons-round text-sm leading-5 text-blue-600 font-medium"
          >
            help
          </div>
        </td>
        <td
          v-else
          class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
          title="sur commande"
        >
          <div
            class="material-icons-round text-sm leading-5 text-blue-600 font-medium"
          >
            help
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Pagination -->
  <pageNumber
    v-if="!searchActive"
    :pageStart="pageStart"
    :pageNext="pageNext"
    :tabList="artTabLength"
    :pageTab="pageTab"
    :pageTotal="Math.ceil(artTabLength / 10)"
    :clickFunction="getArticles"
    :currentPage="currentPage"
    :typeList="'articles'"
    @update:start="pageStart = $event"
    @update:tab="pageTab = $event"
    @update:next="pageNext = $event"
  />

  <!-- Pagination actif searchActive -->
  <pageNumber
    v-if="searchActive"
    :pageStart="pageStart"
    :pageNext="pageNext"
    :tabList="searchLength"
    :pageTab="pageTab"
    :pageTotal="Math.ceil(searchLength / 10)"
    :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
    :currentPage="currentPage"
    :typeList="'articles'"
    @update:start="pageStart = $event"
    @update:tab="pageTab = $event"
    @update:next="pageNext = $event"
  />
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import {
  searchArticleClient,
  prepareArticleClient,
} from "@/hooks/searchEngine/article";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import { getFrDate } from "@/hooks/tools/date.ts";

export default {
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      artTab: [],
      artTabLength: 0,

      currentPage: 1,
      pageTab: [],
      pageNext: 11,
      pageStart: 1,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,

      msgSearch: "",
      openArticle: false,
      articleSelected: "",
      tabDetailArticle: {},

      formattedDateHist: [],
    };
  },
  computed: {
    ...mapGetters(["user", "tabClient", "actionArticle"]),
  },
  components: {
    pageNumber,
    searchInput,
  },
  methods: {
    ...mapActions([
      "addactionArticle",
      "adddataconsigne",
      "adddatanonconsigne",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    async getArticles(skipValue, limitValue) {
      this.currentPage = skipValue;
      const response = await axios.post(
        `${process.env.VUE_APP_API}/savedSearch/getByClient`,
        {
          plateform: this.user.plateform,
          client: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        }
      );
      this.artTab = response.data.tab;
      this.artTabLength = response.data.count;
    },
    // pushDataToModal(data) {
    //   this.tabDetailArticle = data;
    //   this.articleSelected = data.Ref_fournisseur;
    //   this.openArticle = true;
    // },
    async ajoutArticlePanier(data) {
      var article;
      var searchTab = await searchArticleClient(
        data.Ref_fournisseur,
        this.user.plateform,
        this.user.proprietaire,
        this.tabClient.Group.Name
      );

      for (const art of searchTab) {
        if (art.article.Prefixe_tarif == data.Prefixe_tarif) {
          article = art.article;
          break;
        }
      }

      var existArticle = false;
      for (const [index, actArticle] of this.actionArticle.entries()) {
        if (
          actArticle.Ref_fournisseur == article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == article.Prefixe_tarif
        ) {
          this.actionArticle[index].quantity =
            parseInt(this.actionArticle[index].quantity) + parseInt(1);
          existArticle = true;
        }
      }

      if (existArticle == false) {
        const articleData = { article: article, stockData: [] };
        var dataTab = await prepareArticleClient(
          articleData,
          this.user.plateform,
          this.user.proprietaire,
          this.tabClient.Group.Name,
          1
        );

        await this.addactionArticle(dataTab);
        await this.addConsigne(dataTab);
      }
      this.open = false;
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        if (this.user.proprietaire.dsvCheck == false) {
          this.addactionArticle({
            _id: dataConsigne._id,
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
        }
        this.adddataconsigne(dataConsigne);
        this.adddataconsigne({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
      } else {
        this.adddatanonconsigne(dataConsigne);
      }
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getArticles(this.currentPage, 10);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/savedSearch/searchInClient`, {
            plateform: this.user.plateform,
            client: this.user.proprietaire,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.artTab = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
  },
  beforeMount() {
    this.getArticles(1, 10);
    this.pageSwitch();
  },
};
</script>
