<template>
  <div
    class="modal z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center"
    v-if="isOpen && infoInBdl"
    ref="modal"
  >
    <div
      class="modal-content bg-gray-50 p-8 shadow-md rounded-xl border-gray-300"
    >
      <div class="modal-header flex justify-between mb-4">
        <h2 class="text-xl font-bold">Détail du bon de livraison</h2>
        <button
          @click="closeModal"
          class="text-gray-500 hover:text-gray-600 active:text-gray-400 hover:scale-110"
        >
          <span class="material-icons-outlined"> close </span>
        </button>
      </div>
      <div class="modal-body">
        <table class="">
          <thead class="bg-bluevk text-white">
            <tr>
              <th
                class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
              >
                Référence
              </th>
              <th
                class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
              >
                Description
              </th>
              <th
                class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
              >
                Quantité
              </th>
              <th
                class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
              >
                Prix unitaire
              </th>
              <th
                v-if="askReturn == true && showReturnTitles"
                class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
              >
                Quantité retourné
              </th>
              <th
                v-if="askReturn == true && !showReturnTitles"
                class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
              >
                Quantité à retourner
              </th>
              <th
                v-else
                class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
              ></th>
              <th
                v-if="askReturn == true && !showReturnTitles"
                class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
              >
                Demande de retour
              </th>
              <th
                v-else
                class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(uArticle, index) in detail.Articles"
              :key="index"
              style="cursor: pointer"
              class="hover:bg-blue-50"
            >
              <td
                class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
              >
                {{ uArticle.Ref_fournisseur }}
              </td>
              <td
                class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
              >
                {{ uArticle.Description }}
              </td>
              <td
                class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
              >
                {{ uArticle.quantity }}
              </td>
              <td
                class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
              >
                {{ uArticle.prixtotal }} €
              </td>
              <td
                v-if="
                  uArticle.quantity - uArticle.quantityReturned == 0 &&
                  askReturn
                "
                class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
              >
                {{ uArticle.quantityReturned }} PCS retourné
              </td>
              <td
                v-else-if="askReturn"
                class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
              >
                <input
                  v-if="askReturn == true"
                  class="focus:outline-2 outline-sky-300 border"
                  style="width: 6vh"
                  type="number"
                  min="1"
                  :max="uArticle.quantity - uArticle.quantityReturned"
                  v-model="uArticle.quantityPrep"
                />
                / {{ uArticle.quantity - uArticle.quantityReturned }}
              </td>
              <td
                v-if="
                  askReturn == true &&
                  (uArticle.quantityPrep === undefined ||
                    uArticle.quantityPrep == 0)
                "
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                style="width: 1rem"
              ></td>
              <td
                v-else-if="askReturn == true"
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                style="width: 1rem"
              >
                <input
                  type="checkbox"
                  class="focus:outline-2 outline-sky-300"
                  v-model="checkedRef"
                  :value="index"
                />
              </td>
              <td
                v-else
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                style="width: 1rem"
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer" v-if="checkedRef.length >= 1">
        <button
          @click="validate"
          class="rounded-md bg-blue-600 tracking-wide px-6 py-2 font-medium hover:bg-blue-500 focus:outline-none text-white float-right mt-4"
        >
          Valider
        </button>
      </div>
    </div>
  </div>

  <div
    class="modal z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center"
    v-else-if="isOpen && infoInFacture"
    ref="modal"
  >
    <div
      class="modal-content bg-gray-50 p-8 shadow-md rounded-xl border-gray-300 w-full"
    >
      <div class="modal-header flex justify-between mb-4">
        <h2 class="text-xl font-bold">Détail de la Facture</h2>
        <button
          @click="closeModal"
          class="text-gray-500 hover:text-gray-600 active:text-gray-400 hover:scale-110"
        >
          <span class="material-icons-outlined"> close </span>
        </button>
      </div>
      <div class="modal-body">
        <table class="">
          <thead class="bg-bluevk text-white">
            <tr>
              <th
                class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-bluevk"
              >
                Nom du client
              </th>
              <th
                class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-bluevk"
              >
                Date
              </th>
              <th
                class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-bluevk"
              >
                Prix unitaire
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(u, index) in detail.Bdls"
              :key="index"
              style="cursor: pointer"
              class="hover:bg-blue-50"
            >
              <td
                class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
              >
                {{ u.Client }}
              </td>
              <td
                class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
              >
                {{ getFRDate(u.Date) }}
              </td>
              <td
                class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
              >
                {{ u.TotalPrice.toFixed(2) }} €
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer" v-if="checkedRef.length >= 1">
        <button
          @click="validate"
          class="rounded-md bg-blue-600 tracking-wide px-6 py-2 font-medium hover:bg-blue-500 focus:outline-none text-white float-right mt-4"
        >
          Valider
        </button>
      </div>
    </div>
  </div>
  <!--  -->

  <div
    class="z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center"
    v-else-if="isOpen && !infoInFacture && !infoInBdls"
    ref="modal"
  >
    <div
      @click="closeModal"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>
    <div
      style="max-width: 60rem"
      class="z-50 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="modal-content bg-gray-50 p-8 shadow-md rounded-xl border-gray-300"
      >
        <div class="modal-header flex justify-between mb-4">
          <h2 class="text-xl font-bold">Bon de livraison</h2>
          <button
            @click="closeModal"
            class="text-gray-500 hover:text-gray-600 active:text-gray-400 hover:scale-110"
          >
            <span class="material-icons-outlined"> close </span>
          </button>
        </div>
        <div class="modal-body">
          <table class="">
            <thead class="bg-bluevk text-white">
              <tr>
                <th
                  class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
                >
                  Référence
                </th>
                <th
                  class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
                >
                  Quantité
                </th>
                <th
                  class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
                >
                  Prix unitaire
                </th>
                <th
                  v-if="askReturn == true && showReturnTitles"
                  class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
                >
                  Quantité retourné
                </th>
                <th
                  v-if="askReturn == true && !showReturnTitles"
                  class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
                >
                  Quantité à retourner
                </th>
                <th
                  v-else
                  class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
                ></th>
                <th
                  v-if="askReturn == true && !showReturnTitles"
                  class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
                >
                  Demande de retour
                </th>
                <th
                  v-else
                  class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(uArticle, index) in detail.Articles"
                :key="index"
                style="cursor: pointer"
                class="hover:bg-blue-50"
              >
                <td
                  class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                >
                  {{ uArticle.Ref_fournisseur }}
                </td>
                <td
                  class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                >
                  {{ uArticle.Description }}
                </td>
                <td
                  class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                >
                  {{ uArticle.quantity }}
                </td>
                <td
                  class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                >
                  {{ uArticle.prixtotal }} €
                </td>
                <td
                  v-if="
                    uArticle.quantity - uArticle.quantityReturned == 0 &&
                    askReturn
                  "
                  class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                >
                  {{ uArticle.quantityReturned }} PCS retourné
                </td>
                <td
                  v-else-if="askReturn"
                  class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                >
                  <input
                    v-if="askReturn == true"
                    class="focus:outline-2 outline-sky-300 border"
                    style="width: 6vh"
                    type="number"
                    min="1"
                    :max="uArticle.quantity - uArticle.quantityReturned"
                    v-model="uArticle.quantityPrep"
                  />
                  / {{ uArticle.quantity - uArticle.quantityReturned }}
                </td>
                <td
                  v-if="
                    askReturn == true &&
                    (uArticle.quantityPrep === undefined ||
                      uArticle.quantityPrep == 0)
                  "
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                  style="width: 1rem"
                ></td>
                <td
                  v-else-if="askReturn == true"
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                  style="width: 1rem"
                >
                  <input
                    type="checkbox"
                    class="focus:outline-2 outline-sky-300"
                    v-model="checkedRef"
                    :value="index"
                  />
                </td>
                <td
                  v-else
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                  style="width: 1rem"
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer" v-if="checkedRef.length >= 1">
          <button
            @click="validate"
            class="rounded-md bg-blue-600 tracking-wide px-6 py-2 font-medium hover:bg-blue-500 focus:outline-none text-white float-right mt-4"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFrDate } from "@/hooks/tools/date.ts";

export default {
  data() {
    return {
      checkedRef: [],
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    detail: {
      type: Object,
      required: true,
    },
    askReturn: {
      type: Boolean,
      required: true,
    },
    infoInBdl: {
      type: Boolean,
      required: true,
    },
    infoInFacture: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showReturnTitles() {
      return this.detail.Articles.every(
        (uArticle) => uArticle.quantity - uArticle.quantityReturned == 0
      );
    },
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    // handleOutsideClick(event) {
    //   // Vérifiez si le clic a été effectué en dehors du modal
    //   console.log("isOpen:", this.isOpen);
    //   console.log("this.$refs.modal:", this.$refs.modal);
    //   console.log("event.target:", event.target);
    //   if (this.isOpen && this.$refs.modal && !this.$refs.modal.contains(event.target)) {
    //     this.closeModal();
    //   }
    // },
    closeModal() {
      this.$emit("closeModal");
    },
    validate() {
      console.log("checkRefEnfant", this.checkedRef);
      this.$emit("sendCheckRef", this.checkedRef);
    },
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
  },
};
</script>
