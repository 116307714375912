<template>
  <div class="flex h-screen bg-beige-vk font-roboto">
    <div class="flex-1 flex flex-col overflow-hidden">
      <Header />

      <main
        class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100"
      >
        <div
          v-if="$route.path != '/dashboard' && $route.path != '/'"
          class="m-auto p-8"
        >
          <slot />
        </div>
        <div v-else>
          <slot />
        </div>
      </main>
      <!-- <Footer /> -->
    </div>
  </div>
</template>

<script>
// import Slidebar from "./Slidebar.vue";
// import Sidebar from "./Sidebar.vue";
// import Footer from './Footer.vue'
import Header from "./Header.vue";

export default {
  components: { Header },
};
</script>
