export function getFrDate(date: Date) {
  return new Date(date).toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    // weekday: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
}

export function getFrDateSansH(date: Date) {
  return new Date(date).toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

export function getFrDateMonth(date: Date) {
  return new Date(date).toLocaleDateString("fr-FR", {
    month: "2-digit",
  });
}

export function getFrDateYear(date: Date) {
  return new Date(date).toLocaleDateString("fr-FR", {
    year: "numeric",
  });
}