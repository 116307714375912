<template>
  <div class="flex flex-row space-x-1 items-center w-full overflow-x-auto">
    <div v-for="(fam, indexFam) in familles" :key="indexFam">
      <div
        class="h-16 w-16 border border-gray-300 rounded"
        v-if="fam.stateLogo"
      >
        <img
          class="h-full w-full rounded cursor-pointer"
          :src="fam.logo_url"
          @click="getArticles(fam)"
        />
      </div>
      <div
        class="px-1 border border-gray-300 rounded cursor-pointer"
        v-else
        @click="getArticles(fam)"
      >
        <span class="text-xs">{{ fam.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "generalFamille",
  data() {
    return {
      familles: [],
    };
  },
  computed: {
    ...mapGetters(["user", "selectionCarId"]),
  },
  methods: {
    ...mapActions(["setfamilleselected"]),
    async getArticles(famille) {
      this.setfamilleselected({ famille: famille });
      this.$emit("updateFamille");
      this.$router.push("/catalogueTecDocNew");
    },
  },
  async mounted() {
    const getGeneralFamille = await axios.post(
      `${process.env.VUE_APP_API}/configurationCatalogueV2/getGeneralFamille`,
      {
        plateform: this.user.plateform,
        carId: this.selectionCarId,
      }
    );
    this.familles = getGeneralFamille.data;
  },
};
</script>
<style></style>
