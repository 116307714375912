<template>
  <div class="flex flex-row h-full">
    <div class="flex flex-col items-center my-auto">
      <span
        v-if="stock"
        title="Disponible"
        class="material-icons-outlined p-0.5 rounded-full bg-green-500 text-white"
        >check_circle
      </span>

      <span
        v-else-if="!stock && !stockFournisseur && !loadingStock"
        title="Sur commande"
        class="material-icons-outlined p-0.5 rounded-full bg-orange-500 text-white"
        >help_outline</span
      >
      <div v-else-if="!stock && stockFournisseur">
        <span
          v-if="
            (qtyJour > 0 && qtySoir > 0) ||
            (qtyJour > 0 && qtySoir == 0) ||
            (qtyJour == 0 && qtySoir > 0)
          "
          title="Disponible filiale"
          class="material-icons-outlined p-0.5 rounded-full bg-blue-500 text-white"
          >check_circle
        </span>
        <span
          v-else
          title="Sur commande"
          class="material-icons-outlined p-0.5 rounded-full bg-orange-500 text-white"
          >help_outline
        </span>
      </div>

      <span v-else>chargement...</span>
      <!-- <div>
        <span v-if="stock">Disponible</span
        ><span v-else-if="!stock && stockFournisseur">Dispo filiale</span
        ><span v-else-if="!stock && !stockFournisseur && !loadingStock"
          >Sur commande</span
        >
      </div> -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: ["Code_EAN", "qty", "Prefixe_tarif", "article", "marque"],
  data() {
    return {
      stock: null,
      qtyStock: "",
      stockFournisseur: null,
      qtyJour: "",
      qtySoir: "",
      loadingStock: true,
      groupTab: [],
    };
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
    // articlesFusionnes() {
    //   let articles = [...this.disponibles, ...this.filiales, ...this.surCommande];
    //   let articlesDisponibles = articles.filter(a => this.disponibles.includes(a));
    //   let articlesFiliales = articles.filter(a => this.filiales.includes(a) && !this.disponibles.includes(a));
    //   let articlesSurCommande = articles.filter(a => this.surCommande.includes(a));
    //   return [...articlesDisponibles, ...articlesFiliales, ...articlesSurCommande];
    // }
  },
  methods: {
    getGroupement() {
      axios
        .get(
          `${process.env.VUE_APP_API}/plateform/getGroupement/${this.user.plateform}`
        )
        .then((res) => {
          if (!res.data.error) {
            this.groupTab = res.data;
          } else {
            console.log("aucun groupement trouvé");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setDispo() {
      var dispo = "";
      if (this.stock) {
        dispo = "disponible";
        this.$emit("dispo", dispo);
      } else if (!this.stock && !this.stockFournisseur && !this.loadingStock) {
        dispo = "sur commande";
        this.$emit("dispo", dispo);
      }
    },
    async getStock() {
      this.loadingStock = true;
      await axios
        .post(`${process.env.VUE_APP_API}/infoArticle/stockEANArticle`, {
          Code_EAN: this.Code_EAN,
          Code_marque: this.marque,
          plateform: this.user.plateform,
          Ref_fournisseur: this.article,
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.stock = res.data[0].stock;
            if (parseInt(res.data[0].stock) >= this.qty) {
              this.stock = true;
              this.qtyStock = res.data[0].stock;
            } else {
              this.stock = false;
            }
          } else {
            this.stock = false;
          }
          this.getStockFournisseur();
          this.setDispo();
        });
    },
    async getStockFournisseur() {
      if (this.Prefixe_tarif == "Vhi" && !this.stock) {
        axios
          .post(`${process.env.VUE_APP_API}/catalogue/getDispoFournisseur`, {
            Ref_fournisseur: this.article,
            Code_marque: this.marque,
            logCode: this.plateform.grossierID,
            Quantity: this.qty,
            login: this.plateform.grossierID,
            plateform: this.user.plateform,
          })
          .then((response) => {
            this.stockFournisseur = response.data.dispoFournisseur;
            this.qtyJour = response.data.qtyJour;
            this.qtySoir = response.data.qtySoir;
            this.loadingStock = false;
          });
      } else {
        this.stockFournisseur = false;
        this.loadingStock = false;
      }
    },
  },
  beforeMount() {
    this.getStock();
    this.getGroupement();
  },
};
</script>
<style></style>
