<template>
  <div
    v-if="openMenu"
    :class="`modal ${
      !openMenu && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex justify-start`"
  >
    <div
      @click="openMenu = false"
      class="absolute w-screen h-screen z-40 top-0 left-0 bg-gray-600 opacity-50"
    ></div>

    <div
      style="margin-top: 14px"
      class="absolute flex space-x-4 pr-6 z-50 ml-2 mx-auto overflow-y-auto modal-container text-white font-medium"
    >
      <!-- Body -->
      <div class="flex flex-col space-y-3 ml-4">
        <div
          @click="openMenu = false"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 mb-4 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">close</span>
        </div>
        <div
          @click="getAccueil()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">home</span>
        </div>
        <div
          @click="getComptoir()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">calculate</span>
        </div>
        <!-- <div
          @click="getEditDevis()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">edit_note</span>
        </div> -->
        <!-- <div
          @click="getRetour()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">keyboard_return</span>
        </div> -->
        <div
          @click="getListeCommandes()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">format_list_bulleted</span>
        </div>
        <div
          @click="getDevisClient()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">receipt_long</span>
        </div>
        <div
          @click="getDevis()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">description </span>
        </div>
        <div
          @click="getBdc()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">description </span>
        </div>
        <div
          @click="getBdl()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">description </span>
        </div>
        <div
          @click="getListRetour()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">description</span>
        </div>
        <div
          @click="getFacture()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">description </span>
        </div>
        <div
          @click="getPanier()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">shopping_basket </span>
        </div>
        <div
          v-if="tabClient.TableauRemiseState"
          @click="getTableauRemise()"
          class="rounded-full shadow-lg bg-sky-400 w-10 h-10 text-center items-center p-1 cursor-pointer"
        >
          <span class="material-icons-outlined p-1">discount </span>
        </div>
      </div>

      <div class="flex flex-col space-y-4 text-sm mt-4">
        <div class="py-3 h-fit mb-4" @click="openMenu = false"></div>
        <div
          @click="getAccueil()"
          class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
        >
          Accueil
        </div>
        <div
          @click="getComptoir()"
          class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
        >
          Comptoir
        </div>
        <!-- <div
            @click="getEditDevis()"
            class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
          >
            Éditer un devis au client
          </div> -->
        <!-- <div
            @click="getRetour()"
            class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
          >
            Demander un retour
          </div> -->
        <div
          @click="getListeCommandes()"
          class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
        >
          Suivi des commandes
        </div>
        <div
          @click="getDevisClient()"
          class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
        >
          Devis client
        </div>
        <div
          @click="getDevis()"
          class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
        >
          Devis
        </div>
        <div
          @click="getBdc()"
          class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
        >
          Bons de commande
        </div>
        <!-- <div @click="getRetour()" 
            class="py-2 h-fit cursor-pointer text-left">
            Vos retours
          </div> -->
        <div
          @click="getBdl()"
          class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
        >
          Bons de livraison
        </div>
        <div
          @click="getListRetour()"
          class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
        >
          Retours
        </div>
        <div
          @click="getFacture()"
          class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
        >
          Factures
        </div>
        <div
          @click="getPanier()"
          class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
        >
          Paniers / cut off
        </div>
        <div
          v-if="tabClient.TableauRemiseState"
          @click="getTableauRemise()"
          class="p-2 bg-gray-500 w-fit rounded-md h-fit cursor-pointer text-left"
        >
          Tableau des remises
        </div>
      </div>
    </div>
  </div>
  <!-- Fin menu -->

  <div
    @click="openMenu = true"
    class="flex justify-start rounded-full border w-10 h-10 text-center cursor-pointer shadow hover:shadow-lg"
  >
    <!-- <img class="rounded-full" :src="store.state.plateform.lien_logo" /> -->
    <span class="material-icons-outlined p-1.5 text-orange-500">menu</span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MySidenav",
  data() {
    return {
      openMenu: false,
    };
  },
  computed: {
    ...mapGetters(["tabClient"]),
  },
  methods: {
    ...mapActions(["getTabClient"]),
    getAccueil() {
      this.$router.push("./dashboard"), (this.openMenu = false);
    },
    getEditDevis() {
      this.$router.push("./devisClient"), (this.openMenu = false);
    },
    getComptoir() {
      this.$router.push("./commandesf"), (this.openMenu = false);
    },
    getListeCommandes() {
      this.$router.push("./listecommandes"), (this.openMenu = false);
    },
    getRetour() {
      this.$router.push("./demandeRetour"), (this.openMenu = false);
    },
    getDevis() {
      this.$router.push("./devis"), (this.openMenu = false);
    },
    getBdc() {
      this.$router.push("./commandes"), (this.openMenu = false);
    },
    getBdl() {
      this.$router.push("./bdl"), (this.openMenu = false);
    },
    getListRetour() {
      this.$router.push("./retour"), (this.openMenu = false);
    },
    getFacture() {
      this.$router.push("./facture"), (this.openMenu = false);
    },
    getPanier() {
      this.$router.push("./paniersClient"), (this.openMenu = false);
    },
    getDevisClient() {
      this.$router.push("./listeDevisClient"), (this.openMenu = false);
    },
    getTableauRemise() {
      this.$router.push("./TableauRemise"), (this.openMenu = false);
    },
  },
  beforeMount() {
    this.getTabClient();
  },
};
</script>

<style></style>
