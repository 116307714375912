<template>
  <!-- modal info vehicule -->
  <div
    v-if="openInfoVehicule"
    :class="`modal ${
      !openInfoVehicule && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openInfoVehicule = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      v-if="vehiculeSet"
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-height: 55rem; max-width: 80rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <div class="px-6 py-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <div class="flex">
            <p class="text-2xl font-bold">Informations pour :</p>
            <p class="text-2xl font-bold uppercase text-left ml-4">
              {{ vehicule[0].data.AWN_immat }}
            </p>
            <p class="text-2xl ml-44">
              {{ vehicule[0].data.AWN_marque_carrosserie }}
            </p>
            <div class="flex flex-row">
              <p class="text-2xl font-bold uppercase ml-4">
                {{ vehicule[0].data.AWN_modele_etude }}
                {{ vehicule[0].data.AWN_version }}
              </p>
            </div>
          </div>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openInfoVehicule = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div v-if="vehiculeSet" class="mt-4">
          <infosVehicule :vehicule="vehicule" />
        </div>

        <div class="flex justify-end pt-2">
          <button
            @click="openInfoVehicule = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal info vehicule -->

  <!-- HEADER -->
  <header
    class="z-10 flex items-center justify-between px-6 py-2 bg-white drop-shadow-md bg-white"
  >
    <div class="flex items-center">
      <Sidenav />

      <router-link
        to="/dashboard"
        class="ml-8 flex text-black cursor-pointer border rounded-md px-1 bg-gray-100 hover:bg-gray-50 shadow hidden 2xl:flex"
      >
        <div class="flex justify-center my-2 mx-1">
          <div class="flex space-x-2">
            <img :src="plateform.lien_logo" class="m-auto w-9 h-8" alt="" />
            <span class="underline m-auto" style="font-size: 10px">{{
              plateform.Telephone
            }}</span>
          </div>
        </div>
      </router-link>

      <!-- <button
        @click="$store.state.isOpen = true"
        class="text-gray-500 focus:outline-none lg:hidden"
      >
        <svg
          class="w-6 h-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6H20M4 12H20M4 18H11"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button> -->

      <div class="ml-8 flex flex-row items-center">
        <div class="relative -mr-6 z-10 hidden sm:block">
          <router-link to="/tecdocsearch">
            <div class="flex flex-col">
              <span
                style="margin-left: -1px"
                class="material-icons-outlined hover:bg-blue-400 hover:bg-opacity-25 text-white rounded-full cursor-pointer"
              >
                directions_car
              </span>
            </div>
          </router-link>
        </div>
        <div class="relative hidden sm:block" v-if="vehiculeSet">
          <div class="plate-immat">
            <i class="plate-flag fx-center-center wlf wlf-plate-FR"></i>
            <input
              type="text"
              :placeholder="vehicule[0].data.AWN_immat"
              name="plate-number"
              class="uppercase plate-number focus:outline-2 outline-sky-300"
              v-model="plaque"
              v-on:keyup.enter="getPlaque()"
            />
            <i class="plate-flag fx-center-center wlf wlf-plate-FR"></i>
          </div>
        </div>
        <div class="relative hidden sm:block" v-else>
          <div class="plate-immat">
            <i class="plate-flag fx-center-center wlf wlf-plate-FR"></i>
            <input
              type="text"
              placeholder="AB-123-CD"
              name="plate-number"
              class="uppercase plate-number focus:outline-2 outline-sky-300"
              v-model="plaque"
              v-on:keyup.enter="getPlaque()"
            />
            <i class="plate-flag fx-center-center wlf wlf-plate-FR"></i>
          </div>
        </div>
        <div class="relative -ml-6 z-10 hidden sm:block">
          <router-link to="/historiqueplaque">
            <div class="flex flex-col">
              <span
                style="margin-left: -1px"
                class="material-icons-outlined hover:bg-blue-400 hover:bg-opacity-25 text-white rounded-full cursor-pointer"
              >
                history
              </span>
            </div>
          </router-link>
        </div>
        <div class="relative">
          <PulseLoader color="#2d74da" v-show="isLoading" />
        </div>
        <div
          class="flex text-black text-xs border border-sky-400 rounded-md w-fit mx-2 hidden md:flex"
          v-if="vehiculeSet"
        >
          <span
            class="text-left m-auto material-icons-round text-sky-500 cursor-pointer"
            style="font-size: 15px"
            @click="clearvehicule()"
          >
            highlight_off
          </span>
          <div class="flex flex-col px-1">
            <div class="flex space-x-2">
              <span class="hidden lg:block"
                >{{ vehicule[0].data.AWN_marque }}
              </span>
              <span class="">{{ vehicule[0].data.AWN_modele }} </span>
            </div>
            <div class="flex space-x-2 hidden xl:block">
              <span class="">{{ vehicule[0].data.AWN_version }} </span>
              <span class=""
                >{{ vehicule[0].data.AWN_date_mise_en_circulation }}
              </span>
            </div>
          </div>
          <div class="text-right z-10">
            <span
              class="text-right material-icons-round text-sky-500 cursor-pointer"
              style="font-size: 20px"
              @click="openInfoVehicule = true"
            >
              info
            </span>
          </div>
        </div>
        <div
          class="flex text-black text-xs border border-sky-400 rounded-md w-fit mx-2 hidden md:flex"
          v-else-if="vehiculeTD"
        >
          <span
            class="text-left m-auto material-icons-round text-sky-500 cursor-pointer"
            style="font-size: 15px"
            @click="clearvehicule()"
          >
            highlight_off
          </span>
          <div class="flex flex-col px-1" v-if="vehiculeTDData">
            <div class="flex space-x-2">
              <span class="hidden lg:block">{{ vehiculeTDData.marque }} </span>
              <span class="">{{ vehiculeTDData.modele }} </span>
            </div>
            <div class="flex space-x-2 hidden xl:block">
              <span class="">{{ vehiculeTDData.version }} </span>
              <span class="">{{ vehiculeTDData.date }} </span>
            </div>
          </div>
          <div class="text-right z-10">
            <span
              class="text-right material-icons-round text-sky-500 cursor-pointer"
              style="font-size: 20px"
              @click="openInfoVehicule = true"
            >
              info
            </span>
          </div>
        </div>
        <!-- if vehicule is manually selected  -->
        <div
          class="flex text-black text-xs border border-sky-400 rounded-md w-fit mx-2 hidden md:flex"
          v-else-if="selectionCarId != null"
        >
          <span
            class="text-left m-auto material-icons-round text-sky-500 cursor-pointer"
            style="font-size: 15px"
            @click="
              setSelectionCarId({ carId: null }), window.location.reload()
            "
          >
            highlight_off
          </span>
          <div class="flex flex-col px-1">
            <div class="flex space-x-2">
              <span class="">{{ selectionCarData.manuName }} </span>
            </div>
            <div class="flex space-x-2">
              <span class="">{{ selectionCarData.modelselected }} </span>
            </div>
          </div>
        </div>
        <div class="relative ml-4">
          <router-link to="/catalogueHubNew">
            <!-- <img src="../assets/tecdoc_logo.jpg" style="height: 37px" alt=""/> -->
            <div class="flex flex-col">
              <span
                class="material-icons-outlined hover:bg-blue-400 hover:bg-opacity-25 p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
              >
                auto_stories
              </span>
              <span class="mt-0.5 m-auto" style="font-size: 9px">
                catalogue
              </span>
            </div>
          </router-link>
          <!-- <router-link to="/tecdocsearch" v-else>
            <div class="flex flex-col">
              <span
                class="material-icons-outlined hover:bg-blue-400 hover:bg-opacity-25 p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
              >
                auto_stories
              </span>
              <span class="mt-0.5 m-auto" style="font-size: 9px">
                catalogue
              </span>
            </div>
          </router-link> -->
        </div>
        <div v-if="tabClient && tabClient?.catalogue_carcat">
          <div
            class="relative ml-6 z-20"
            style="height: 30px"
            v-if="carcatToken.access_token != ''"
          >
            <!-- <a
                class="m-auto"
                href="https://lkq-carsys-fr-staging.auth.eu-central-1.amazoncognito.com/login?client_id=2s9qagpalko23h8fvnt2u66df5&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://cl.vekteur.fr/"
              >
                <span
                  class="material-icons-outlined p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
                >
                  login
                </span>
              </a> -->

            <div class="relative flex justify-center -mt-2.5">
              <div class="flex flex-col">
                <menuCarcat @click="showBackCarcat = !showBackCarcat" />
                <span
                  class="m-auto mt-8"
                  style="font-size: 9px"
                  @click="closeCarCat()"
                  >CarCat</span
                >
              </div>
            </div>
          </div>
          <div
            class="flex flex-col relative ml-6 z-20 -mt-4"
            style="width: 30px; height: 30px"
            v-else
          >
            <a
              class="m-auto"
              href="https://lkq-carsys-fr-production.auth.eu-central-1.amazoncognito.com/login?client_id=1q5491gklchf6dil2njt62d77f&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://cl.vekteur.fr"
            >
              <img src="@/assets/CarCat.png" alt="" />
            </a>
            <span class="m-auto mt-0.5" style="font-size: 9px"> CarCat </span>
          </div>
        </div>

        <div class="flex ml-6 relative z-10">
          <div
            class="flex flex-col"
            @click="
              (showPneuInfo = !showPneuInfo), $router.push('/cataloguedepneus')
            "
          >
            <img
              src="../assets/tire_icon.png"
              alt=""
              class="hover:bg-blue-400 hover:bg-opacity-25 bg-white p-1.5 w-8 h-8 m-auto border rounded-full text-blue-500 cursor-pointer"
            />
            <span class="m-auto" style="font-size: 9px"> pneus </span>
          </div>
          <div
            class="m-auto text-black text-xs border border-sky-400 rounded-md w-72 mx-2"
            v-if="vehiculeSet && vehicule[0].data.AWN_pneus"
            v-show="showPneuInfo"
          >
            <div class="flex px-1">
              <span class="">{{ vehicule[0].data.AWN_pneus }} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex m-auto items-center space-x-4 mx-6 hidden xl:flex">
        <!-- VANWEZEL -->
        <div class="flex flex-col space-y-7 m-auto">
          <a href="https://vwa.autopartscat.com/" target="_blank">
            <img
              src="../assets/Fournisseurs/VanWezel.jpg"
              alt=""
              @mouseover="showNameVanWezel = true"
              @mouseleave="showNameVanWezel = false"
              style="width: 32px; height: 28px"
              class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer z-10"
          /></a>
          <div
            v-show="showNameVanWezel"
            style="font-size: 12px"
            class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black z-20"
          >
            <img
              src="../assets/Fournisseurs/VanWezel.jpg"
              alt=""
              style="width: 300px; height: 250px"
            />
          </div>
        </div>

        <!-- KRAFTWERK -->
        <div class="flex flex-col space-y-7 m-auto">
          <a href="https://www.kraftwerktools.fr/fr-fr/" target="_blank">
            <img
              src="../assets/Fournisseurs/kraftwerk.png"
              @mouseover="showNameKraftwerk = true"
              @mouseleave="showNameKraftwerk = false"
              style="width: 32px; height: 28px"
              class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer z-10"
          /></a>
          <div
            v-show="showNameKraftwerk"
            style="font-size: 12px"
            class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black z-20"
          >
            <img
              src="../assets/Fournisseurs/kraftwerk.png"
              style="width: 300px; height: 250px"
            />
          </div>
        </div>

        <!-- JBM -->
        <div class="flex flex-col space-y-7 m-auto">
          <a href="https://www.jbmcamp.com/fr/" target="_blank">
            <img
              src="../assets/Fournisseurs/Jbm.png"
              @mouseover="showNameJbm = true"
              @mouseleave="showNameJbm = false"
              style="width: 32px; height: 28px"
              class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer z-10"
          /></a>
          <div
            v-show="showNameJbm"
            style="font-size: 12px"
            class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black z-20"
          >
            <img
              src="../assets/Fournisseurs/Jbm.png"
              style="width: 300px; height: 250px"
            />
          </div>
        </div>

        <!-- IGOL -->
        <div class="flex flex-col space-y-7 m-auto">
          <a href="https://www.igol.com/" target="_blank">
            <img
              src="../assets/Fournisseurs/Igol.jpg"
              @mouseover="showNameIgol = true"
              @mouseleave="showNameIgol = false"
              style="width: 32px; height: 28px"
              class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer z-10"
          /></a>
          <div
            v-show="showNameIgol"
            style="font-size: 12px"
            class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black z-10"
          >
            <img
              src="../assets/Fournisseurs/Igol.jpg"
              style="width: 300px; height: 250px"
            />
          </div>
        </div>

        <!-- Sodise -->
        <div class="flex flex-col space-y-7 m-auto">
          <a href="https://www.sodise.com/" target="_blank">
            <img
              src="../assets/Fournisseurs/Sodise.jpg"
              @mouseover="showNameSodise = true"
              @mouseleave="showNameSodise = false"
              style="width: 32px; height: 28px"
              class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer z-10"
          /></a>
          <div
            v-show="showNameSodise"
            style="font-size: 12px"
            class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black z-10"
          >
            <img
              src="../assets/Fournisseurs/Sodise.jpg"
              style="width: 300px; height: 250px"
            />
          </div>
        </div>

        <!-- TABCUTOFF -->
        <!-- <div class="flex flex-col space-y-7 m-auto">
          <div
            v-for="(line, index) in tabClient.tabCutOff.sort((a, b) => {
              var dateA = new Date('1970/01/01 ' + a.cutoff);
              var dateB = new Date('1970/01/01 ' + b.cutoff);
              return dateA - dateB;
            })"
            :key="index"
            @mouseover="showTabCutOff = true"
            @mouseleave="showTabCutOff = false"
          >
            <ProgressCircleOne
              v-if="index == 0"
              :progress="panierTransport.valeur"
              :maxValue="parseInt(franco)"
              :cutoff="line.cutoff"
              :typeLivraison="line.schematransport"
            />
          </div>
          <div
            v-if="index == 0"
            v-show="showTabCutOff"
            @mouseover="showTabCutOff = true"
            @mouseleave="showTabCutOff = false"
            style="font-size: 12px"
            class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black z-10"
          >
            <div v-if="panierTransport" class="">
              <div class="flex justify-center">
                <div
                  v-for="(line, index) in tabClient.tabCutOff.sort((a, b) => {
                    var dateA = new Date('1970/01/01 ' + a.cutoff);
                    var dateB = new Date('1970/01/01 ' + b.cutoff);
                    return dateA - dateB;
                  })"
                  :key="index"
                >
                  <ProgressCircle
                    :progress="panierTransport.valeur"
                    :maxValue="parseInt(franco)"
                    :cutoff="line.cutoff"
                    :typeLivraison="line.schematransport"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="flex items-center">
      <!-- <div
        class="text-gray-600 mr-8 text-xs hidden 2xl:block"
        v-if="parseFloat(panierTransport.valeur) < franco"
      >
        <div>
          Commande de la journée :<span
            class="text-gray-800 font-semibold ml-1"
          >
            {{ financial(panierTransport.valeur) }}€</span
          >
        </div>
        <div>
          Montant restant :
          <span class="text-gray-800 font-semibold"
            >{{ financial(franco - financial(panierTransport.valeur)) }} €</span
          >
        </div>
      </div> -->
      <!-- <div class="text-gray-600 mr-8 text-xs hidden 2xl:block" v-else>
        <div>
          Commande de la journée :<span
            class="text-gray-800 font-semibold ml-1"
          >
            {{ financial(panierTransport.valeur) }}€</span
          >
        </div>
        <div class="flex items-center">
          <span class="mr-2">Frais de port offert</span>
          <span
            class="material-icons-outlined rounded-full text-white bg-green-700 ml-1 text-lg px-1"
            >done</span
          >
        </div>
      </div> -->

      <div
        class="flex flex-col items-center mr-8 px-2 py-1 shadow rounded border w-fit hidden sm:block text-xs sm:text-xs lg:text-base"
      >
        <div class="flex flex-row items-center">
          <input
            type="text"
            v-model="searchInput"
            placeholder="Rechercher un article"
            class="px-2 py-1 focus:outline-2 outline-sky-300"
            @keyup.enter="
              searchRefFournisseur({ Ref_fournisseur: searchInput })
            "
          />
          <span
            class="material-icons-outlined cursor-pointer text-xs lg:text-base xl:text-xl"
            @click="searchRefFournisseur({ Ref_fournisseur: searchInput })"
          >
            search
          </span>
          <span
            class="material-icons-round cursor-pointer text-xs hover:bg-blue-500 hover:text-white hover:rounded lg:text-base xl:text-xl"
            @click="
              searchRefFournisseurDetail({ Ref_fournisseur: searchInput })
            "
          >
            manage_search
          </span>
        </div>
      </div>

      <div class="flex items-center">
        <div class="flex items-center hidden md:block">
          <!-- panier -->
          <div class="relative">
            <div class="flex mr-1.5">
              <button
                class="mx-4 relative z-10 block w-8 h-8 focus:outline-none"
              >
                <div class="flex flex-col">
                  <span
                    @click="documentOpenPanier = !documentOpenPanier"
                    class="material-icons-round hover:bg-blue-400 hover:bg-opacity-25 p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
                  >
                    shopping_cart
                  </span>
                  <span
                    class="bg-sky-500 px-1 cursor-pointer hover:bg-sky-400 text-white rounded-full"
                    @click="getPanier()"
                    style="font-size: 9px"
                  >
                    panier
                  </span>
                </div>
              </button>

              <div
                style="margin-left: 42px; font-size: 10px; margin-top: -2px"
                class="absolute z-10 border rounded-full w-5 h-5 bg-red-700 text-white"
              >
                <span class="m-auto px-1.5">{{ articleTotal }}</span>
              </div>
            </div>

            <div
              v-show="documentOpenPanier"
              @click="documentOpenPanier = false"
              class="fixed inset-0 h-full w-full z-10"
            ></div>

            <div
              v-show="documentOpenPanier"
              class="absolute right-0 mt-2 w-8 bg-white text-xs rounded-lg shadow-xl overflow-y-auto overflow-x-hidden z-10"
              style="width: 20rem; max-height: 32rem"
            >
              <router-link to="/commandesf" @click="documentOpenPanier = false">
                <div
                  class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 mx-2"
                  v-for="(article, index) in actionArticle"
                  :key="index"
                  @click="documentOpenPanier = false"
                >
                  <span class="material-icons-round"> article </span>
                  <div class="text-xs mx-2">
                    <span class="font-bold text-blue-400 mr-1" href="#"
                      >N° {{ article.Ref_fournisseur }}</span
                    >
                    <span class="font-bold" href="#">
                      {{ article.Libelle }}</span
                    >
                    : {{ parseFloat(article.Prix_euro).toFixed(2) }} €
                  </div>
                </div>

                <div
                  class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
                >
                  <span class="material-icons-round"> local_offer </span>
                  <p class="text-xs mx-2">
                    <span class="font-bold" href="#">Total TTC : </span>
                    <span class="font-bold text-blue-400" href="#">
                      {{ parseFloat(setTotalTTC).toFixed(2) }} €</span
                    >
                  </p>
                </div>
              </router-link>
              <div class="grid grid-cols-2">
                <div
                  class="flex w-28 cursor-pointer items-center my-1 px-2 py-2 text-gray-600 border rounded-md hover:text-white hover:bg-blue-500 hover:opacity-40 m-auto"
                  @click="clearPanier(), (documentOpenPanier = false)"
                >
                  <span class="material-icons-outlined" style="color: red"
                    >delete_forever</span
                  >
                  <button class="text-xs">
                    <span class="font-bold" href="#">Vider le panier</span>
                  </button>
                </div>
                <router-link
                  to="/commandesf"
                  @click="documentOpenPanier = false"
                >
                  <div
                    class="flex w-28 cursor-pointer items-center my-1 px-2 py-2 text-gray-600 border rounded-md hover:text-white hover:bg-blue-500 hover:opacity-40 m-auto"
                    @click="documentOpenPanier = false"
                  >
                    <button class="text-xs">
                      <span class="font-bold" href="#">Passer la commande</span>
                    </button>
                  </div>
                </router-link>
              </div>
            </div>
          </div>

          <!-- notification -->
          <!-- <div class="relative">
          <button
            @click="notificationOpen = !notificationOpen"
            class="ml-6 relative z-10 block w-8 h-8 focus:outline-none m-auto"
          >
            <div class="flex flex-col">
              <span
                class="material-icons-round hover:bg-blue-400 hover:bg-opacity-25 ml-1 p-0.5 w-8 m-auto border rounded-full text-blue-500 cursor-pointer"
              >
                notifications
              </span>
              <span style="font-size: 9px"> notification </span>
            </div>
          </button>

          <div
            v-show="notificationOpen"
            @click="notificationOpen = false"
            class="fixed inset-0 h-full w-full z-10"
          ></div>

          <div
            v-show="notificationOpen"
            class="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-xl overflow-hidden z-10"
            style="width: 20rem"
          >
            <a
              href="#"
              class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
            >
              <span class="material-icons-round">verified</span>
              <p class="text-sm mx-2">
                <span class="font-bold" href="#">Garage 1</span> Pièce reçu
                <span class="font-bold text-blue-400" href="#"
                  >N° 1276523H</span
                >
                . 2m
              </p>
            </a>
            <a
              href="#"
              class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
            >
              <span class="material-icons-round">notifications_active</span>
              <p class="text-sm mx-2">
                <span class="font-bold" href="#">Garage 6</span> A signé le
                devis . 45m
              </p>
            </a>
            <a
              href="#"
              class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
            >
              <span class="material-icons-round">error</span>
              <p class="text-sm mx-2">
                <span class="font-bold" href="#">Garage 3</span> Prépa prête
                <span class="font-bold text-blue-400" href="#">N° 238723D</span>
                . 1h
              </p>
            </a>
            <a
              href="#"
              class="flex items-center px-4 py-3 text-gray-600 hover:text-white hover:bg-blue-500 -mx-2"
            >
              <span class="material-icons-round">notifications_active</span>
              <p class="text-sm mx-2">
                <span class="font-bold" href="#">Garage 2</span>
                Prépa lancé . 3h
              </p>
            </a>
          </div>
        </div> -->
        </div>

        <!-- Profil -->
        <div class="relative">
          <button
            @click="dropdownOpen = !dropdownOpen"
            class="flex relative z-10 block w-fit text-sm overflow-hidden bg-gray-50 rounded-full focus:outline-none"
          >
            <div
              class="p-1 flex flex-col w-fit"
              style="margin-left: auto; margin-right: auto"
            >
              <div class="flex relative shadow-lg rounded-full">
                <span class="material-icons-round pl-2 text-blue-500"
                  >person</span
                >
                <p class="hover:text-gray-400 text-sm pr-1">
                  {{ user.username }}
                </p>
              </div>
              <div>
                <p
                  class="mx-auto text-gray-800 w-fit px-2 h-4"
                  style="font-size: 9px"
                >
                  {{ user.proprietaire }}
                </p>
              </div>
            </div>
          </button>

          <div
            v-show="dropdownOpen"
            @click="dropdownOpen = false"
            class="fixed inset-0 z-50 w-screen h-screen"
          ></div>

          <transition
            enter-active-class="transition duration-150 ease-out transform"
            enter-from-class="scale-95 opacity-0"
            enter-to-class="scale-100 opacity-100"
            leave-active-class="transition duration-150 ease-in transform"
            leave-from-class="scale-100 opacity-100"
            leave-to-class="scale-95 opacity-0"
          >
            <div
              v-show="dropdownOpen"
              class="absolute right-0 z-50 w-48 py-1 mt-2 bg-white rounded-lg shadow-xl"
            >
              <a
                href="#"
                @click="getProfil()"
                class="px-4 py-2 flex rounded-md text-sm text-gray-700 hover:bg-blue-500 hover:text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
                Profil
              </a>
              <div
                class="flex px-4 py-2 rounded-md text-sm text-gray-700 hover:bg-blue-500 hover:text-white"
                style="cursor: pointer"
                @click="deconnexion()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
                Déconnexion
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </header>
  <div
    v-if="$route.path != '/dashboard' && $route.path != '/'"
    class="border-b-4 border-orange-600"
  ></div>
</template>

<script>
import infosVehicule from "@/components/infosVehicule.vue";
import { getAuth, signOut } from "firebase/auth";
import { mapActions, mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import axios from "axios";
import Sidenav from "../components/Sidenav.vue";
import menuCarcat from "../components/Header/menuCarcat.vue";
import { useToast } from "vue-toastification";
import { getPanierTransport } from "@/hooks/transport/panierTransport.ts";
// import ProgressCircle from "@/components/ProgressCircle.vue";
// import ProgressCircleOne from "@/components/ProgressCircleOne.vue";

export default {
  name: "MyHeader",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      openModalSearch: false,
      openModalSearchVanHeck: false,
      openModalArticle: false,
      dropdownOpen: false,
      notificationOpen: false,
      documentOpen: false,
      isLoading: false,
      plaque: "",
      documentOpenPanier: false,
      searchInput: "",
      openInfoVehicule: false,
      index: 0,
      showIconDispoA: [],
      showIconDispoB: [],
      showIconFiliale1A: [],
      showIconFiliale1B: [],
      showIconFiliale2A: [],
      showIconFiliale2B: [],
      showIconCmdA: [],
      showIconCmdB: [],
      tabRefVH: [],
      showCatRef: true,
      autoCompleteTab: [],
      showAutoComplete: false,
      showBackCarcat: false,
      showPneuInfo: false,
      articleInfo_componentKey: 0,
      showNameVanWezel: false,
      showNameKraftwerk: false,
      showNameJbm: false,
      showNameIgol: false,
      showNameSodise: false,
      showTabCutOff: false,

      franco: "",

      panierTransport: {},
    };
  },
  components: {
    PulseLoader,
    Sidenav,
    menuCarcat,
    infosVehicule,
    // ProgressCircle,
    // ProgressCircleOne,
  },
  computed: {
    ...mapGetters([
      "vehicule",
      "actionArticle",
      "user",
      "plateform",
      "crossoetab",
      "generalloading",
      "prixtab",
      "carcatToken",
      "carCatLink",
      "carCatReference",
      "vehiculeSet",
      "selectionCarId",
      "selectionCarData",
      "tabClient",
      "vehiculeTD",
      "vehiculeTDData",
    ]),
    articleTotal() {
      return this.actionArticle.length;
    },
    setTotal() {
      var total = 0.0;
      this.actionArticle.forEach((element) => {
        total = total + parseFloat(element.Prix_euro);
      });
      return total;
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
  },
  methods: {
    ...mapActions([
      "deconnecter",
      "addvehicule",
      "clearvehicule",
      "clearactionArticle",
      "searchCrossOe",
      "searchCross",
      "searchDirect",
      "getPrix",
      "searchTranslatedDirectAdd",
      "storecarcattoken",
      "clearcarcattoken",
      "addpaniercarcat",
      "setcarcatlinks",
      "setSelectionCarId",
      "setArticleInfo",
      "searchRefFournisseur",
      "getTabClient",
      "storerefreshcarcattoken",
      "searchRefFournisseurDetail",
      "setUser"
    ]),
    async getPanierTransportClient() {
      this.panierTransport = await getPanierTransport(
        this.user.plateform,
        this.tabClient?._id
      );
    },
    deconnexion() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.deconnecter();
        this.clearcarcattoken();
        this.$router.push("/login");
      });
      // .catch((error) => {
      //   //log error
      // });
    },
    clearPanier() {
      this.clearactionArticle();
    },
    getRefSimilaire(ref) {
      axios
        .post(`${process.env.VUE_APP_API}/vanheck/getRefSimilaire`, {
          Ref_fournisseur: ref,
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.tabRefVH = response.data;
          this.showCatRef = false;
        })
        .catch(() => {
          this.tabRefVH = [];
        });
    },
    getProfil() {
      this.$router.push("/profil");
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    getPanier() {
      this.$router.push("/commandesf");
    },
    getPlaque() {
      this.clearvehicule();
      this.isLoading = true;
      // this.vehiculeSet = true;
      this.setSelectionCarId({ carId: null });
      axios
        .post(`${process.env.VUE_APP_API}/plaque`, {
          plaque: this.plaque,
          Owner: this.user.proprietaire,
        })
        .then((response) => {
          // this.addvehicule(response.data);
          if (response.data.error) {
            this.toast.error("La plaque n'existe pas !", {
              position: "bottom-right",
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          } else {
            this.addvehicule(response.data);
            this.$router.push("/catalogueHubNew");
            this.plaque = "";
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    async initCarCatLogin() {
      await this.$router.isReady();
      if (this.$route.query.code) {
        // axios
        //   .get(
        //     `${process.env.VUE_APP_API}/carcat/getToken/${this.$route.query.code}`
        //   )
        //   .then((res) => {
        //   });
        axios
          .post(
            `https://lkq-carsys-fr-production.auth.eu-central-1.amazoncognito.com/oauth2/token`,
            `grant_type=authorization_code&code=${this.$route.query.code}&client_id=1q5491gklchf6dil2njt62d77f&redirect_uri=https://cl.vekteur.fr`,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Basic ${btoa(
                  "1q5491gklchf6dil2njt62d77f:k1m3ri382088l9qo9s04mpadn7if35vqmea0k3hreauj4psa4p2"
                )}`,
              },
            }
          )
          .then((result) => {
            this.storecarcattoken(result.data);
          })
          .catch(() => {
            this.clearcarcattoken();
          });
      }
    },
    openCarCat() {
      axios
        .post(
          `https://lkq-europe-prod.apigee.net/gms-api-public-iframe-routes-fr/iframe/v2/ticket`,
          {
            IDToken: this.carcatToken.id_token,
            ReferenceIdentifier:
              "referenceVekteur" + Math.floor(Math.random() * 1000000000),
            // OrderCreationBehaviour: "force_new_order",
            // ShowNavigation: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.carcatToken.access_token}`,
              "X-api-key": "IbW4A7bqa447M2c7WhFwagVtbMjL9DLd",
            },
          }
        )
        .then((result) => {
          this.setcarcatlinks({
            link: result.data.Data.TicketURL,
            ref: result.data.Data.ReferenceIdentifier,
          });
          // this.openCarCatModal = true;
          this.$router.push("/carcat");
        })
        .catch(() => {
          this.refreshCarCatToken();
        });
    },
    async refreshCarCatToken() {
      await axios
        .post(
          `https://lkq-carsys-fr-production.auth.eu-central-1.amazoncognito.com/oauth2/token`,
          `grant_type=refresh_token&refresh_token=${this.carcatToken.refresh_token}&client_id=1q5491gklchf6dil2njt62d77f&redirect_uri=https://cl.vekteur.fr`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Basic ${btoa(
                "1q5491gklchf6dil2njt62d77f:k1m3ri382088l9qo9s04mpadn7if35vqmea0k3hreauj4psa4p2"
              )}`,
            },
          }
        )
        .then((result) => {
          this.storerefreshcarcattoken(result.data);
          this.openCarCat();
        })
        .catch((error) => {
          console.log(error);
          this.clearcarcattoken();
        });
    },
    closeCarCat() {
      axios
        .post(
          `https://lkq-europe-prod.apigee.net/gms-api-public-iframe-routes-fr/iframe/retrieve-orders`,
          {
            IDToken: this.carcatToken.id_token,
            ReferenceIdentifier: this.carCatReference,
            PerPage: 1000,
            Page: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.carcatToken.access_token}`,
              "X-api-key": "IbW4A7bqa447M2c7WhFwagVtbMjL9DLd",
            },
          }
        )
        .then((result) => {
          // this.openCarCatModal = false;
          result.data.Data.Workorders[0].Lines.forEach((element) => {
            this.addpaniercarcat(element.LineCode);
          });
          this.$router.push("/actionclient");
        })
        .catch(() => {
          this.clearcarcattoken();
        });
    },
    async getPluginArticles() {
      await this.$router.isReady();
      if (this.$route.query.codeArticle) {
        this.searchInput = this.$route.query.codeArticle;
        this.searchRefFournisseur({
          Ref_fournisseur: this.$route.query.codeArticle,
        });
      }
    },
    success() {
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    autoComplete(ref) {
      if (this.searchInput != "") {
        axios
          .get(`${process.env.VUE_APP_API}/article/autocomplete/${ref}`)
          .then((response) => {
            this.showAutoComplete = true;
            this.autoCompleteTab = response.data;
          });
      } else {
        this.showAutoComplete = false;
      }
    },
    plusInfo(Ref, code) {
      this.articleInfo_componentKey++;
      this.openModalArticle = true;
      this.setArticleInfo({
        Ref_fournisseur: Ref,
        Code_marque: code,
      });
    },
    // getGroupClient() {
    //   axios
    //     .post(
    //       `${process.env.VUE_APP_API}/client/getone/${this.user.proprietaire}`,
    //       {
    //         plateform: this.user.plateform,
    //       }
    //     )
    //     .then((response) => {
    //       if (response.data[0]?.professionnel) {
    //         this.franco = response.data[0]?.francodeport;
    //       } else {
    //         this.fraisdeport = "0";
    //         this.transport = "0";
    //       }
    //     });
    // },
  },
  async beforeMount() {
    this.getPanierTransportClient();
  },
  async mounted() {
    // await this.setUser();
    await this.getTabClient();
    // this.getGroupClient();
    this.initCarCatLogin();
    this.getPluginArticles();
  },
};
</script>
<style scoped>
.plate-immat {
  max-width: 253px;
  height: 42px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  --tw-border-opacity: 1;
  border: 1px solid rgb(45 116 218 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(45 116 218 / var(--tw-bg-opacity));
}
.plate-number {
  height: 100%;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgb(45 116 218 / var(--tw-border-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}
.plate-flag {
  width: 2rem;
  text-align: center;
  font-size: 2rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
</style>
