<template>
  <div>Prix Brut : {{ prixBrut }} €</div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      prixBrut: "--",
      userGroupClient: "",
    };
  },
  props: ["article", "marque", "Prefixe_tarif", "qty"],
  computed: {
    ...mapGetters(["user", "tabClient"]),
  },
  async mounted() {
    axios
      .post(`${process.env.VUE_APP_API}/catalogue/getPrixDispo`, {
        Ref_fournisseur: this.article,
        Code_marque: this.marque,
        Prefixe_tarif: this.Prefixe_tarif,
        plateform: this.user.plateform,
        client: this.user.proprietaire,
        groupClient: this.tabClient.Group.Name,
        Quantity: this.qty,
      })
      .then((response) => {
        response.data.prixBrut != "--"
          ? (this.prixBrut = parseFloat(response.data.prixBrut).toFixed(2))
          : (this.prixBrut = "--");
      });
  },
};
</script>
<style></style>
