<template>
  <div
    class="flex flex-row items-center font-bold bg-blue-500 trans text-center text-white p-2 shadow-lg cursor-pointer"
    @click="addPanier(Ref_fournisseur, Code_marque)"
  >
    <span
      class="material-icons-round text-white cursor-pointer mx-auto text-lg"
    >
      add_shopping_cart
    </span>
    <span class="text-sm">Ajouter au panier</span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useToast } from "vue-toastification";
export default {
  props: ["Ref_fournisseur", "Code_marque"],
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["searchTranslatedDirectAdd"]),
    addPanier(article, marqueName) {
      this.searchTranslatedDirectAdd({
        artNum: article,
        marqueName: marqueName,
        quantityAdded: 1,
        plateform: this.user.plateform,
      });
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style scoped>
.bg-vk-orange {
  background-color: #ff914d;
}

.bg-vk-orange:hover {
  background-color: #eb8648;
  border-radius: 2rem;
}

.trans {
  transition: border-radius 0.15s;
}

.bg-vk {
  background-color: #2262b3;
}
</style>
