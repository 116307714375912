<template>
  <div>
    <iframe style="height: 85vh" class="w-full" :src="carCatLink" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  computed: {
    ...mapGetters(["carcatToken", "carCatLink", "carCatReference"]),
  },
  methods: {
    ...mapActions([
      "storecarcattoken",
      "clearcarcattoken",
      "addpaniercarcat",
      "setcarcatlinks",
    ]),
    closeCarCat() {
      axios
        .post(
          `https://lkq-europe-prod.apigee.net/gms-api-public-iframe-routes-fr/iframe/retrieve-orders`,
          {
            IDToken: this.carcatToken.id_token,
            ReferenceIdentifier: this.carCatReference,
            PerPage: 1000,
            Page: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.carcatToken.access_token}`,
              "X-api-key": "IbW4A7bqa447M2c7WhFwagVtbMjL9DLd",
            },
          }
        )
        .then((result) => {
          result.data.Data.Workorders[0].Lines.forEach((element) => {
            this.addpaniercarcat(element.LineCode);
          });
        })
        .catch((error) => {
          this.clearcarcattoken();
        });
    },
  },
};
</script>
<style></style>
